.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.6;
  z-index: 92;
  width: 100%;
  height: 100%;
}
.smallModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: var(--primary-bg);
  padding: 32px 24px 24px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 93;
  text-align: center;
}
.smallModal p {
  line-height: 140%;
  color: #fafafa;
}

.btnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.invertBtn {
  height: 37px;
  color: var(--primary-color);
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid white;
  padding: 0px 45px;
}
.invertBtn:hover {
  background-color: white;
  color: black;
}
.button {
  height: 37px;
  border-radius: 10px;
  padding: 0px 45px;
}
