.explore {
  padding: 60px 0;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding-bottom: 20px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  text-align: center;
  color: #fafafa;
  border: 1px solid #fafafa;
  border-radius: 10px;
  padding: 12px 24px;
  cursor: pointer;
  transition: opacity 0.2s;
}
.button:hover {
  opacity: 0.9;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
  align-items: center;
  gap: 15px;
  padding-bottom: 50px;
}

.nftContainer {
  aspect-ratio: 1;
  overflow: hidden;
}

.nftImage {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.topUser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #0c1232;
  border-radius: 10px;
  padding: 15px;
}
.userImage {
  width: 80px;
  height: 80px;
}
.userName,
.category {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: #fafafa;
}
.addFriend {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  color: #303030;
  background: #fafafa;
  border-radius: 10px;
  padding: 12px 22px;
  cursor: pointer;
  min-width: 130px;
  transition: opacity 0.2s;
}
.addFriend:hover {
  opacity: 0.9;
}

.collection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.category {
  text-align: center;
  padding-top: 5px;
}
.image {
  width: 100%;
}
.info {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: rgba(250, 250, 250, 0.7);
}
@media only screen and (max-width: 520px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .button {
    padding: 8px 20px;
    font-size: 12px;
    margin-left: auto;
  }
  .title {
    font-size: 28px;
  }
}
