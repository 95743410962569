.connectWalletButton {
  width: min-content;
  color: #fafafa;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border-radius: 25px;
  background: #3069ff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  cursor: pointer;
  border: none;
  outline: none;
  white-space: nowrap;
  transition: opacity 0.2s;
}
.connectWalletButton:hover {
  opacity: 0.9;
}
.icon {
  width: 14px;
}
@media only screen and (max-width: 380px) {
  .connectWalletButton {
    font-size: 12px;
    padding: 6px 10px;
    gap: 5px;
  }
  .icon {
    width: 12px;
  }
}
