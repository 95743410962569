.whotoFollow {
  background: #0c1232;
  border-radius: 20px;
  padding: 15px 20px;
  max-width: 326px;
  width: 100%;
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 1;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 125%;
  color: #ffffff;
  padding-bottom: 10px;
}
.userWrapper {
  max-height: 80vh;
  overflow-y: auto;
}
.userContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.uerImageAndName {
  display: flex;
  align-items: center;
  gap: 8px;
}
.userImage {
  width: 50px;
  height: 50px;
}
.userName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #fafafa;
  cursor: pointer;
}
.addFriend {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  color: #303030;
  background: #fafafa;
  border-radius: 10px;
  padding: 8px 8px;
  cursor: pointer;
  min-width: 100px;
  transition: opacity 0.2s;
}
.addFriend:hover {
  opacity: 0.9;
}
.showMore {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #42ccd7;
  cursor: pointer;
  padding-top: 10px;
}
@media only screen and (max-width: 1199px) {
  .whotoFollow {
    display: none;
  }
  .showWhotoFollow {
    display: block;
  }
}
@media only screen and (max-width: 520px) {
  .whotoFollow {
    right: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media only screen and (max-width: 400px) {
  .whotoFollow {
    padding: 20px 10px;
  }
}
