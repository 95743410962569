.chatRoom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  user-select: none;
  cursor: pointer;
  padding: 10px 5px;
  border-radius: 5px;
}
.chatRoom img,
.placeHolderImg {
  width: 45px;
  object-fit: contain;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.placeHolderImg {
  background-color: #fafafa;
}
.groupImages {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  isolation: isolate;
}
.groupImages img:nth-child(2) {
  position: absolute;
  left: 25%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.groupImages img:nth-child(3) {
  position: absolute;
  left: 45%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -2;
}
.chatRoom > img,
.groupImages img {
  min-width: 45px;
  max-width: 45px;
  border-radius: 50%;
  object-fit: contain;
}
.rightRoom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  color: white;
  width: 100%;
}
.roomDetails {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}
.rightRoom h4 {
  font-size: 16px;
  font-weight: 400;
  color: #fafafa;
}
.rightRoom p {
  font-size: 14px;
}
.rightRoom p span {
  color: #41c6da;
}

.unread {
  color: var(--primary-bg);
  background-color: #41c6da;
  width: 23px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 500;
  display: grid;
  place-items: center;
}

.lastMessage {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 10px;
}
