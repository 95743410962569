.wrapper {
  width: 100%;
  position: relative;
  z-index: 2;
  padding-top: 40px;
}
.postDetails {
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 25px;
}
.header {
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
}
.backArrow {
  color: #fff;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: -60px;
  transform: translateY(-50%);
  cursor: pointer;
}
.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.price {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #fafafa;
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
}
.ethIcon {
  width: 11.4px;
  height: 19.2px;
}
.name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  color: #42ccd7;
  padding-bottom: 30px;
}
.imageContainer {
  border-radius: 40.5128px;
  max-width: 553px;
}
.image {
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 40.5128px;
}
.detailsContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 557px;
  width: 99%;
}

@media only screen and (max-width: 1280px) {
  .postDetails {
    max-width: calc(100% - 150px);
  }
}
@media only screen and (max-width: 1199px) {
  .postDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 0 auto;
    max-width: 100%;
  }
}
@media only screen and (max-width: 520px) {
  .backArrow {
    color: #fff;
    font-size: 30px;
    position: absolute;
    top: -15px;
    left: 0;
    cursor: pointer;
  }

  .title {
    font-size: 28px;
  }
  .heading {
    font-size: 18px;
  }
  .price {
    font-size: 13px;
  }
  .name {
    font-size: 18px;
  }
}
