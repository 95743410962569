.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.6;
  z-index: 90;
  width: 100%;
  height: 100%;
}
.smallModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: var(--primary-bg);
  padding: 32px 24px 24px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 91;
  text-align: center;
}
.smallModal p {
  line-height: 140%;
  color: #fafafa;
}

.btnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.invertBtn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid white;
  padding: 10px 45px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.invertBtn:hover {
  background-color: white;
  color: black;
}
.button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 10px 45px;
  cursor: pointer;
}
.buttonLoader {
  display: flex;
  justify-content: center;
}
