.pageContainer {
  padding-bottom: 30px;
}
.profileHeader {
  display: grid;
  grid-template-columns: 1fr 400px;
  align-items: start;
  gap: 50px;
  padding-top: 65px;
  z-index: 2;
  position: relative;
}
.profileHeader::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 40%;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #343ab9;
  opacity: 0.8;
  filter: blur(300.176px);
  z-index: -1;
}
.userInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.user {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  width: 120px;
  height: 120px;
  cursor: pointer;
}
.profileImage {
  position: absolute;
  z-index: 3;
  width: 355px;
  height: 355px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}
.name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #fafafa;
  padding-top: 10px;
}
.addressContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  /* background: rgba(250, 250, 250, 0.15); */
  border-radius: 10px;
  padding: 4px 0px;
}
.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(250, 250, 250, 1);
}
.address {
  color: #fff;
}
.copyIcon {
  cursor: pointer;
}
.actionContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  position: relative;
}

.button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #fafafa;
  outline: none;
  border: 1px solid #fafafa;
  border-radius: 10px;
  background: transparent;
  padding: 9px 20px;
  cursor: pointer;
}
.buttonLoader {
  display: flex;
  justify-content: center;
}
.activeButton {
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border: none;
}
.infoContainer {
  grid-column: 1/-1;
  gap: 10px;
  background: #0c1232;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.mutualImages {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  isolation: isolate;
  margin-right: 20px;
}
.collectionImgages {
  max-width: 25px;
}
.mutualImages .mutualImage:nth-child(2) {
  position: absolute;
  left: 25%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.mutualImages .mutualImage:nth-child(3) {
  position: absolute;
  left: 45%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -2;
}

.mutualImage {
  min-width: 32px;
  max-width: 32px;

  object-fit: contain;
}
.mutualCollection {
  border-radius: 5px;
}

.infoText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: #fafafa;
  white-space: nowrap;
  padding: 5px;
}
.url {
  color: #41c5db;
}
.postTypesContainer {
  padding: 30px 0;
  display: flex;
  gap: 25px;
  justify-content: space-between;
  align-items: center;
}
.postTypes {
  background: rgba(250, 250, 250, 0.05);
  border: 1px solid rgba(250, 250, 250, 0.5);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  width: 249px;
  padding: 1px;
}
.postType {
  border-radius: 8px;
  padding: 6.5px 15px;
  width: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease;
}
.activePostType {
  background: rgba(255, 255, 255, 0.3);
  transition: 0.3s ease;
}
.postTypeText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(250, 250, 250, 1);
}
.activePostType .postTypeText {
  font-weight: 700;
}
@media only screen and (max-width: 991px) {
  .profileHeader {
    grid-template-columns: 1fr;
    padding: 30px 0;
  }
  .profileImage {
    width: 250px;
    height: 250px;
  }

  .profileHeader::before {
    display: none;
  }
  .user {
    position: inherit;
    width: 100px;
    height: 100px;

    transform: translate(0px);
  }
  .actionContainer {
    max-width: 400px;
  }
  .actionContainer::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #343ab9;
    opacity: 0.8;
    filter: blur(300.176px);
    z-index: -1;
  }
}
@media only screen and (max-width: 520px) {
  .postCategoryContainer {
    flex-direction: column;
  }

  .profileImage {
    width: 200px;
    height: 200px;
  }
}
