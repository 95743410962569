.nftContainer {
  aspect-ratio: 1;
  overflow: hidden;
}

.nftImage {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}
