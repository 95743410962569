.image {
  width: 100%;
}
.post {
  background: rgba(250, 250, 250, 0.05);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 16px;
}
.detailsContainer {
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.id {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
}
.more {
  color: #fff;
  font-size: 22px;
  cursor: pointer;
}
.moreButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 24px;
  background: #0c1232;
  border-radius: 10px;
  border: 1px solid rgba(250, 250, 250, 0.2);
  min-width: 170px;
  padding: 15px;
}
.moreButton {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #fafafa;
  cursor: pointer;
}
.valueContainer {
  background: #224dbd;
  border-radius: 10px;
  padding: 6px 8px;
  display: flex;
  align-content: center;
  gap: 8px;
}
.value {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #fafafa;
}
.lastSale {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: rgba(250, 250, 250, 0.72);
}
