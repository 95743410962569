.posts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 350px));
  gap: 5px;
}

.posts:not(:has(div)) {
  color: #fff;
  font-family: var(--secondary-font);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
