.postContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  padding-top: 40px;
}
.postsWrapper {
  max-width: 602px;
  margin-right: 360px;
  width: 100%;
  padding: 0 35px;
}

.posts {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.postsWrapper::-webkit-scrollbar,
.posts::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.postsWrapper,
.posts {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.arrow {
  color: #fafafa;
  font-size: 32px;
  display: none;
  position: fixed;
  right: 0;
  top: 110px;
  cursor: pointer;
}
@media only screen and (max-width: 1199px) {
  .postsWrapper {
    margin-right: 0px;
  }
  .arrow {
    display: block;
  }
  .postWrapper {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 991px) {
  .posts {
    max-height: calc(100vh - 205px);
  }
  .arrow {
    top: 170px;
  }
}
@media only screen and (max-width: 520px) {
  .postsWrapper {
    padding: 0 5px;
  }
}
