.progressWrapper {
  height: 4px;
  border-radius: 200;
  overflow: hidden;
}

.progressBar {
  height: 100%;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 200;
  transition: background-color 0.3s ease-out;
}

.progress {
  height: 100%;
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 200;
  transition: width 0.3s ease-out;
}
