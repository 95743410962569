.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 92;
  width: 100%;
  display: flex;
  justify-content: center;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(9, 13, 36, 0.67);
  z-index: 91;
  cursor: pointer;
}
