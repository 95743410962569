.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 88;
  cursor: pointer;
}
.modalWrapp {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 480px;
  max-height: 468px;
  overflow-y: auto;
  z-index: 90;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px 30px;
  border-radius: 8px;
  background-color: var(--primary-bg);
  height: 90%;
}
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 15px 0;
}
.modalHeader h3 {
  color: white;
  font-size: 22px;
  cursor: pointer;
}
.modalHeader svg {
  width: 30px;
  object-fit: contain;
  cursor: pointer;
}

.suggestionDiv {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;
}
.suggestionDiv h3 {
  font-size: 20px;
  color: white;
  font-weight: 700;
}
.suggestedPerson {
  display: flex;

  align-items: center;
  width: 100%;
  gap: 10px;
}
.leftSuggest {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.userImage {
  width: 45px;
  height: 45px;
  object-fit: contain;
  border: 50%;
}
.leftSuggest p {
  color: white;
  font-size: 16px;
  font-weight: 500;
}
@media only screen and (max-width: 520px) {
  .modalWrapp {
    padding: 20px 20px;
  }
}
