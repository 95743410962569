.post {
  background: #ffffff0f;
  backdrop-filter: blur(5px);
  border-radius: 20px;
  width: 100%;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;
}
.userContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.userImg {
  width: 49px;
  height: 44.1px;
  display: block;
}
.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: var(--primary-color);
}
.userName {
  cursor: pointer;
}
.subText {
  font-weight: 400;
}
.more {
  color: var(--primary-color);
  font-size: 24px;
  cursor: pointer;
}
.imageContainer {
  width: 100%;
  /* max-height: 443px; */
  height: 100%;
  position: relative;
}
.nftImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.userIcon {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 20px;
  bottom: 10px;
  z-index: 1;
  cursor: pointer;
}
.informationContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 15px 20px;
}
.information {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.likeCommentSend {
  display: flex;
  align-items: center;
  gap: 20px;
}
.like,
.comment {
  display: flex;
  align-items: center;
  gap: 3px;
}
.icon {
  width: 20px;
  height: 17.8px;
  cursor: pointer;
}

.number {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.72);
}
.idAndbutton {
  display: flex;
  align-items: center;
  gap: 15px;
}
.button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #42ccd7;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
}
.aboutPost {
  display: flex;
  align-items: center;

  gap: 10px;
}
.time {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
}
.owner {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
}
.ethContainer {
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 5px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  cursor: pointer;
}
.eth {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;

  color: #fafafa;
}
.ethIcon {
  width: 11.4px;
  height: 19.2px;
}

.hexagon {
  width: 50px;
  height: 50px;
}

.hexagon:first-child {
  -webkit-clip-path: polygon(
    92.32051% 40%,
    93.79385% 43.1596%,
    94.69616% 46.52704%,
    95% 50%,
    94.69616% 53.47296%,
    93.79385% 56.8404%,
    92.32051% 60%,
    79.82051% 81.65064%,
    77.82089% 84.50639%,
    75.35575% 86.97152%,
    72.5% 88.97114%,
    69.3404% 90.44449%,
    65.97296% 91.34679%,
    62.5% 91.65064%,
    37.5% 91.65064%,
    34.02704% 91.34679%,
    30.6596% 90.44449%,
    27.5% 88.97114%,
    24.64425% 86.97152%,
    22.17911% 84.50639%,
    20.17949% 81.65064%,
    7.67949% 60%,
    6.20615% 56.8404%,
    5.30384% 53.47296%,
    5% 50%,
    5.30384% 46.52704%,
    6.20615% 43.1596%,
    7.67949% 40%,
    20.17949% 18.34936%,
    22.17911% 15.49361%,
    24.64425% 13.02848%,
    27.5% 11.02886%,
    30.6596% 9.55551%,
    34.02704% 8.65321%,
    37.5% 8.34936%,
    62.5% 8.34936%,
    65.97296% 8.65321%,
    69.3404% 9.55551%,
    72.5% 11.02886%,
    75.35575% 13.02848%,
    77.82089% 15.49361%,
    79.82051% 18.34936%
  );
  clip-path: polygon(
    92.32051% 40%,
    93.79385% 43.1596%,
    94.69616% 46.52704%,
    95% 50%,
    94.69616% 53.47296%,
    93.79385% 56.8404%,
    92.32051% 60%,
    79.82051% 81.65064%,
    77.82089% 84.50639%,
    75.35575% 86.97152%,
    72.5% 88.97114%,
    69.3404% 90.44449%,
    65.97296% 91.34679%,
    62.5% 91.65064%,
    37.5% 91.65064%,
    34.02704% 91.34679%,
    30.6596% 90.44449%,
    27.5% 88.97114%,
    24.64425% 86.97152%,
    22.17911% 84.50639%,
    20.17949% 81.65064%,
    7.67949% 60%,
    6.20615% 56.8404%,
    5.30384% 53.47296%,
    5% 50%,
    5.30384% 46.52704%,
    6.20615% 43.1596%,
    7.67949% 40%,
    20.17949% 18.34936%,
    22.17911% 15.49361%,
    24.64425% 13.02848%,
    27.5% 11.02886%,
    30.6596% 9.55551%,
    34.02704% 8.65321%,
    37.5% 8.34936%,
    62.5% 8.34936%,
    65.97296% 8.65321%,
    69.3404% 9.55551%,
    72.5% 11.02886%,
    75.35575% 13.02848%,
    77.82089% 15.49361%,
    79.82051% 18.34936%
  );
  object-fit: cover;
  object-position: center;
  background-color: #616478;
}

@media only screen and (max-width: 400px) {
  .text {
    font-size: 14px;
  }
  .informationContainer {
    padding: 18px 10px;
  }
  .icon {
    width: 18px;
    height: 15.4px;
    cursor: pointer;
  }
  .number {
    font-size: 12px;
  }
}
