.messageWrap {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: transparent;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 101px;
  position: relative;
  z-index: 1;
}
.messageCont {
  /* max-height: calc(100vh - 151px); */
  display: grid;
  grid-template-columns: 400px 1.5fr;
  column-gap: 20px;
  width: 100%;
  align-items: flex-start;
  max-width: 90%;
}

.shadow1 {
  left: 0;
  bottom: 0;
}
.shadow2 {
  top: 0;
  right: 0;
}
.messageRooms {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: var(--primary-bg);
  border-radius: 16px;
  position: static;
  z-index: 1;
  max-height: calc(100vh - 160px);
  left: -600px;
}
.roomHeader {
  border-bottom: 1px solid #fafafa33;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-block: 20px;
  padding-inline: 20px;
}
.roomHeader h2 {
  color: #fafafa;
  font-weight: 700;
  font-size: 32px;
}
.newChatBtn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 140%; */
  height: 40px;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 12px 30px;
  cursor: pointer;
}
.paddingDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding-inline: 20px;
  padding-block: 20px;
  width: 100%;
  overflow-y: hidden;
}
.scrollDiv {
  overflow-y: auto;
  flex-grow: 1;
  max-height: 100%;
  padding-right: 10px;
  width: 100%;
}
.roomSearch {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(250, 250, 250, 0.2);
  border-radius: 10px;
  height: 45px;
  gap: 10px;
  padding-inline: 10px;
}
.roomSearch input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-family: "Roboto";
}
.roomSearch svg {
  min-width: 20px;
  min-height: 20px;
  object-fit: contain;
  fill: #fafafab8;
}
/* //chat room css ------------------ */
.roomChat {
  position: relative;
  z-index: 2;
  width: 100%;
  background-color: var(--primary-bg);
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  border-radius: 16px;
  padding-bottom: 30px;
  max-height: calc(100vh - 160px);
}
.chatPart {
  width: 100%;
}
.inputDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding-inline: 40px;
}
.inputDiv button {
  display: none;
}
.inputDiv > input {
  border: 1.5px solid #fafafa80;
  width: 100%;
  border-radius: 10px;
  height: 40px;
  color: #fafafa80;
  font-size: 16px;
  font-weight: 400;
  padding-left: 15px;
  background-color: transparent;
}
.rightInput {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.rightInput img {
  cursor: pointer;
}
.mobToggle,
.overlay {
  display: none;
}

@media screen and (max-width: 1200px) {
  .roomHeader h2 {
    font-size: 20px;
  }

  .roomHeader,
  .inputDiv,
  .paddingDiv {
    padding-inline: 20px;
  }
  .mobToggle {
    display: inline-block;
    width: max-content;
  }
  .mobToggle svg {
    color: white;
    font-size: 25px;
    cursor: pointer;
  }
  .messageWrap {
    padding-inline: 20px;
    margin-top: 101px;
  }
  .messageCont {
    max-width: 100%;
    grid-template-rows: auto 1fr;
    row-gap: 20px;
    grid-template-columns: 1fr;
    max-height: none;
    height: auto;
  }
  .messageRooms {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 91;
    max-height: 100%;
    max-width: 350px;
    transition: all 0.7s ease-in-out;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 90;
    cursor: pointer;
    background-color: black;
    opacity: 0.7;
    display: block;
  }
  .roomChat {
    max-height: 100%;
    max-height: 650px;
  }
  .sideBarLeft {
    left: 270px;
  }
}
@media screen and (max-width: 991px) {
  .messageWrap {
    margin-top: 165px;
  }
  .roomChat {
    max-height: 600px;
  }
  .sideBarLeft {
    left: 0px;
  }
}
