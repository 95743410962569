@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&family=Work+Sans:wght@400;500;600;700&display=swap);
@font-face {
  font-family: "Akira Expanded";

  src: url("/static/media/Akira Expanded Demo.51985533.otf");
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #000;
  color: #fafafa;
  color: var(--primary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  outline: none;
}

:root {
  /* background-color */
  --primary-bg: #0c1232;
  --button-bg: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  /* text-color */
  --primary-color: #fafafa;

  /* font-family */
  --primary-font: "Roboto", sans-serif;
  --secondary-font: "Akira Expanded", sans-serif;
}

body,
html {
  font-family: "Roboto", sans-serif;
  font-family: var(--primary-font);
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.background {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -9999;
  background-image: radial-gradient(220px 190px at 89% 35%, #343ab955, transparent 150%),
    radial-gradient(220px 190px at 15% 60%, #343ab933, transparent 150%),
    linear-gradient(293.96deg, #12193b -26.79%, #141a42 36.61%, #000000 100%);
}

button {
  border: none;
  outline: none;
  background: none;
}

a {
  color: inherit;
}

.seoTitle {
  text-decoration: underline;
}

.container {
  /* max-width: 1440px; */
  width: 100%;
  margin: 0 auto;
}

.rightSide {
  padding-left: 265px;
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.wrapper {
  width: 90%;
  margin: 0 auto;
}

.marginTop {
  margin-top: 101px;
}

.shadow {
  background: #343ab9;
  opacity: 0.6;
  -webkit-filter: blur(140.176px);
          filter: blur(140.176px);
  width: 100%;
  max-width: 318px;
  aspect-ratio: 1/1;
  position: absolute;
  border-radius: 50%;
  z-index: 1;
  pointer-events: none;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
.grScrollbar {
  scrollbar-width: thin;
  scrollbar-color: #224dbd rgba(250, 250, 250, 0.2);
}

/* Chrome, Edge, and Safari */
.grScrollbar::-webkit-scrollbar {
  width: 5px;
}

.grScrollbar::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
}

.grScrollbar::-webkit-scrollbar-thumb {
  background: linear-gradient(180.6deg, #224dbd 6.54%, #3069ff 45.43%, #42ccd7 94.5%);
  border-radius: 100vh;
  border: none;
}

:is(input, textarea) {
  color: rgba(250, 250, 250, 0.72);
}

:is(input, textarea)::-webkit-input-placeholder {
  font-weight: 400;
  color: rgba(250, 250, 250, 0.72);
}

:is(input, textarea)::placeholder {
  font-weight: 400;
  color: rgba(250, 250, 250, 0.72);
}

.primaryButton {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #fafafa;
  color: var(--primary-color);
  background: #3069ff;
  border-radius: 25px;
  padding: 12px 16px 14px;
  cursor: pointer;
  transition: all 0.2s;
  box-sizing: border-box;
}

.primaryButton:hover {
  opacity: 0.9;
}

.primaryButton[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

/* Chrome,
Safari,
Edge,
Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 991px) {
  .rightSide {
    padding: 0;
  }
  .marginTop {
    margin-top: 165px;
  }
}

.styles_hexagon__1zSyc {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: teal;
  -webkit-clip-path: polygon(
    98.66025% 45%,
    99.39693% 46.5798%,
    99.84808% 48.26352%,
    100% 50%,
    99.84808% 51.73648%,
    99.39693% 53.4202%,
    98.66025% 55%,
    78.66025% 89.64102%,
    77.66044% 91.06889%,
    76.42788% 92.30146%,
    75% 93.30127%,
    73.4202% 94.03794%,
    71.73648% 94.48909%,
    70% 94.64102%,
    30% 94.64102%,
    28.26352% 94.48909%,
    26.5798% 94.03794%,
    25% 93.30127%,
    23.57212% 92.30146%,
    22.33956% 91.06889%,
    21.33975% 89.64102%,
    1.33975% 55%,
    0.60307% 53.4202%,
    0.15192% 51.73648%,
    0% 50%,
    0.15192% 48.26352%,
    0.60307% 46.5798%,
    1.33975% 45%,
    21.33975% 10.35898%,
    22.33956% 8.93111%,
    23.57212% 7.69854%,
    25% 6.69873%,
    26.5798% 5.96206%,
    28.26352% 5.51091%,
    30% 5.35898%,
    70% 5.35898%,
    71.73648% 5.51091%,
    73.4202% 5.96206%,
    75% 6.69873%,
    76.42788% 7.69854%,
    77.66044% 8.93111%,
    78.66025% 10.35898%
  );
  clip-path: polygon(
    98.66025% 45%,
    99.39693% 46.5798%,
    99.84808% 48.26352%,
    100% 50%,
    99.84808% 51.73648%,
    99.39693% 53.4202%,
    98.66025% 55%,
    78.66025% 89.64102%,
    77.66044% 91.06889%,
    76.42788% 92.30146%,
    75% 93.30127%,
    73.4202% 94.03794%,
    71.73648% 94.48909%,
    70% 94.64102%,
    30% 94.64102%,
    28.26352% 94.48909%,
    26.5798% 94.03794%,
    25% 93.30127%,
    23.57212% 92.30146%,
    22.33956% 91.06889%,
    21.33975% 89.64102%,
    1.33975% 55%,
    0.60307% 53.4202%,
    0.15192% 51.73648%,
    0% 50%,
    0.15192% 48.26352%,
    0.60307% 46.5798%,
    1.33975% 45%,
    21.33975% 10.35898%,
    22.33956% 8.93111%,
    23.57212% 7.69854%,
    25% 6.69873%,
    26.5798% 5.96206%,
    28.26352% 5.51091%,
    30% 5.35898%,
    70% 5.35898%,
    71.73648% 5.51091%,
    73.4202% 5.96206%,
    75% 6.69873%,
    76.42788% 7.69854%,
    77.66044% 8.93111%,
    78.66025% 10.35898%
  );
}
.styles_hexagon__1zSyc:first-child {
  -webkit-clip-path: polygon(
    92.32051% 40%,
    93.79385% 43.1596%,
    94.69616% 46.52704%,
    95% 50%,
    94.69616% 53.47296%,
    93.79385% 56.8404%,
    92.32051% 60%,
    79.82051% 81.65064%,
    77.82089% 84.50639%,
    75.35575% 86.97152%,
    72.5% 88.97114%,
    69.3404% 90.44449%,
    65.97296% 91.34679%,
    62.5% 91.65064%,
    37.5% 91.65064%,
    34.02704% 91.34679%,
    30.6596% 90.44449%,
    27.5% 88.97114%,
    24.64425% 86.97152%,
    22.17911% 84.50639%,
    20.17949% 81.65064%,
    7.67949% 60%,
    6.20615% 56.8404%,
    5.30384% 53.47296%,
    5% 50%,
    5.30384% 46.52704%,
    6.20615% 43.1596%,
    7.67949% 40%,
    20.17949% 18.34936%,
    22.17911% 15.49361%,
    24.64425% 13.02848%,
    27.5% 11.02886%,
    30.6596% 9.55551%,
    34.02704% 8.65321%,
    37.5% 8.34936%,
    62.5% 8.34936%,
    65.97296% 8.65321%,
    69.3404% 9.55551%,
    72.5% 11.02886%,
    75.35575% 13.02848%,
    77.82089% 15.49361%,
    79.82051% 18.34936%
  );
  clip-path: polygon(
    92.32051% 40%,
    93.79385% 43.1596%,
    94.69616% 46.52704%,
    95% 50%,
    94.69616% 53.47296%,
    93.79385% 56.8404%,
    92.32051% 60%,
    79.82051% 81.65064%,
    77.82089% 84.50639%,
    75.35575% 86.97152%,
    72.5% 88.97114%,
    69.3404% 90.44449%,
    65.97296% 91.34679%,
    62.5% 91.65064%,
    37.5% 91.65064%,
    34.02704% 91.34679%,
    30.6596% 90.44449%,
    27.5% 88.97114%,
    24.64425% 86.97152%,
    22.17911% 84.50639%,
    20.17949% 81.65064%,
    7.67949% 60%,
    6.20615% 56.8404%,
    5.30384% 53.47296%,
    5% 50%,
    5.30384% 46.52704%,
    6.20615% 43.1596%,
    7.67949% 40%,
    20.17949% 18.34936%,
    22.17911% 15.49361%,
    24.64425% 13.02848%,
    27.5% 11.02886%,
    30.6596% 9.55551%,
    34.02704% 8.65321%,
    37.5% 8.34936%,
    62.5% 8.34936%,
    65.97296% 8.65321%,
    69.3404% 9.55551%,
    72.5% 11.02886%,
    75.35575% 13.02848%,
    77.82089% 15.49361%,
    79.82051% 18.34936%
  );
  object-fit: cover;
  object-position: center;
  background-color: #616478;
}

@-webkit-keyframes Loader_spinner__1oBcK {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes Loader_spinner__1oBcK {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.Loader_loadingContainer__2ivQB {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: 100%;
}
.Loader_loadingSpinner__pXAof {
  width: 55px;
  height: 55px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #0c1232; /* Black */
  border-radius: 50%;
  -webkit-animation: Loader_spinner__1oBcK 1.5s linear infinite;
          animation: Loader_spinner__1oBcK 1.5s linear infinite;
}
@media only screen and (max-width: 520px) {
  .Loader_loadingSpinner__pXAof {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #0c1232; /* Black */
  }
}

.styles_overlay__3upqz {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0);
  opacity: 0.6;
  z-index: 12;
  width: 100vw;
  height: 100%;
  cursor: pointer;
}
.styles_modalWrapper__ZxyCO {
  max-width: 480px;
  width: 90%;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-bg);

  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 91;
}

.styles_smallModal__SEkGh {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 25px;
  gap: 25px;
  padding: 25px 0;
  text-align: center;
  max-width: 320px;
  width: 100%;
}
.styles_connectUnsuccessFull__1dwZv {
  max-width: 390px;
  width: 100%;
  padding: 25px 0;
  padding-top: 45px;
  position: relative;
}
.styles_close__39oe9 {
  position: absolute;
  right: -25px;
  top: 15px;
  color: #fff;
  font-size: 24px;
  z-index: 1;
  cursor: pointer;
}
.styles_heading__3O0kn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 125%px;
  text-align: center;

  /* Continent Full Moon */

  color: #fafafa;
}
.styles_title__3O_IM {
  padding: 15px 0;
}
.styles_profileInfo__3AMdx {
  max-width: 252px;
  width: 100%;
  border: 1.5px solid rgba(250, 250, 250, 0.72);
  border-radius: 16px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  padding: 8px 12px;
}

.styles_prifilePic__3gfc3 {
  width: 40px;
  height: 42px;
  object-fit: contain;
  object-position: center;
}

.styles_nameAndAddress__QR1fN {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.styles_name__1DRbn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: var(--primary-color);
}
.styles_address__3rVFK,
.styles_text__2Veug {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: rgba(250, 250, 250, 0.72);
}

.styles_btnDiv__2REaB {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.styles_invertBtn__2R8rz {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid white;
  padding: 10px 45px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.styles_invertBtn__2R8rz:hover {
  background-color: white;
  color: black;
}
.styles_button__2n5aC {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 8px 45px;
  cursor: pointer;
  width: 100%;
}
.styles_subsCribe__2N1zo {
  width: auto;
  padding: 8px 15px;
}
.styles_subsCribeContainer__1TGhg {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.styles_input__WHpqV {
  width: 100%;
  outline: none;
  border: 1px solid rgba(250, 250, 250, 0.72);
  border-radius: 10px;
  padding: 8px 15px;
  background: transparent;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffff;
}
.styles_error__1VqVA {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%px;
  text-align: center;

  /* Continent Full Moon */

  color: #ff0000;
}
@media only screen and (max-width: 520px) {
  .styles_title__3O_IM {
    padding: 0;
  }
  .styles_button__2n5aC {
    font-size: 16px;
  }
}
@media only screen and (max-width: 400px) {
  .styles_modalWrapper__ZxyCO {
    padding: 20px 20px;
  }
  .styles_connectUnsuccessFull__1dwZv {
    width: 100%;
  }
  .styles_subsCribeContainer__1TGhg {
    flex-direction: column;
  }
}

.styles_overlay__1Z2Sb {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 88;
}
.styles_modalWrapp__3MHSk {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90%;
  max-width: 700px;
  max-height: 490px;
  overflow-y: auto;
  z-index: 90;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 30px;
  border-radius: 8px;
  height: 90%;
  background-color: var(--primary-bg);
}
.styles_modalHeader__uWouh {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}
.styles_modalHeader__uWouh h3 {
  color: white;
  font-size: 22px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}
.styles_modalHeader__uWouh svg {
  min-width: 30px;
  max-width: 30px;
  object-fit: contain;
  cursor: pointer;
}
.styles_photosDiv__2FLoJ {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  grid-auto-rows: 1fr;
  width: 100%;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
  max-height: 320px;
  height: 100%;
  padding-right: 10px;
  overflow-y: auto;
}
.styles_photosDiv__2FLoJ button {
  width: 100%;
  aspect-ratio: 1/1;
  background-color: transparent;
  cursor: pointer;
}
.styles_photosDiv__2FLoJ button img {
  border-radius: 8px;
  border: 4px solid transparent;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
  height: 100%;
}
.styles_button__Ck2oF {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 10px 45px;
  cursor: pointer;
}

.styles_setUpProfile__39F4a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  min-height: calc(100vh - 101px);
}
.styles_heading__3FwW3 {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 115%;
  text-align: center;
  color: #ffff;
  padding-bottom: 10px;
  padding: 0 10px;
}

.styles_profilePhotoDiv__bnnht {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}
.styles_profilePhotoDiv__bnnht button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #41c6da;
  padding-bottom: 8px;
  cursor: pointer;
}
.styles_profilePhoto__OvSuy {
  width: 100px;
  height: 105px;
}
.styles_userNameContainer__V7Btr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 322px;
  width: 95%;
  margin: 0 auto;
}
.styles_inputContainer__1uZ2G {
  border: 1px solid rgba(250, 250, 250, 0.72);
  border-radius: 10px;
  padding: 10px 12px;
  background: transparent;
  width: 100%;
  position: relative;
  transition: 0.2s ease;
}
.styles_warningBorder__3_8vp {
  border: 1.5px solid #fe595a;
}
.styles_paddingTop__14YcQ {
  padding-top: 24px;
  transition: 0.2s ease;
}
.styles_label__1z07b {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #ffff;
  opacity: 0.6;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  transition: 0.2s ease;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: text;
}
.styles_showLabel__1I0Oc {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  top: 5px;

  transition: 0.2s ease;
}
.styles_warning__30yul {
  color: #fe595a;
  opacity: 1;
  text-align: left;
  display: block;
  width: 100%;
}
.styles_warningText__cqhSj {
  padding-left: 12px;
  padding-top: 2px;
}
.styles_input__OheYy {
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffff;
}
.styles_button__2zncC {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 10px 45px;
  width: 100%;
  cursor: pointer;
  margin-top: 15px;
}
.styles_disableStyle__1XgvT {
  cursor: not-allowed;
  opacity: 0.6;
}
.styles_buttonLoader__18Bkb {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 991px) {
  .styles_setUpProfile__39F4a {
    min-height: calc(100vh - 165px);
  }
}
@media only screen and (max-width: 520px) {
  .styles_heading__3FwW3 {
    font-size: 32px;
  }
}

.styles_container__veMxC {
  display: flex;

  min-height: calc(100vh - 101px);
}

.ConnectWalletModal_wrapper__1vyrb {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  max-width: 525px;
  width: 95%;
  margin: 0 auto;
  padding: 30px;
  position: relative;
  overflow-y: auto;
}
.ConnectWalletModal_modal__AWpto {
  position: absolute;
  top: 80px;

  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 92;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 40px;
  background: #0c1232;
}
.ConnectWalletModal_overlay__ERmLG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(9, 13, 36, 0.9);
  z-index: 91;
  cursor: pointer;
}
.ConnectWalletModal_header__1pkYq {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  width: 100%;
  margin-bottom: 20px;
}
.ConnectWalletModal_title__1Yza8 {
  color: #fafafa;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}

.ConnectWalletModal_close__3zXxA {
  font-size: 20px;
  color: #fafafa;
  margin-left: auto;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  top: 33px;
  right: 33px;
}
.ConnectWalletModal_logoContainer__hJugq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 2px;
  gap: 2px;
}
.ConnectWalletModal_logo__z1_fM {
  width: 173px;
  padding-right: 3px;
}

.ConnectWalletModal_walletContainer__1E85w {
  display: flex;
  align-items: center;
  flex-direction: column;

  grid-gap: 18px;

  gap: 18px;
  max-width: 299px;
  width: 100%;
  margin: 0 auto;
}
.ConnectWalletModal_tagline__1KaHn {
  color: #fafafa;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.ConnectWalletModal_tabContainer__1LiMn {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
}
.ConnectWalletModal_tab__3z4s_ {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  border-radius: 22px;
  border: 1px solid rgba(250, 250, 250, 0.2);
  background: rgba(250, 250, 250, 0.1);
  padding: 7px 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  outline: none;
  transition: 0.2s;
}
.ConnectWalletModal_tab__3z4s_:not(.ConnectWalletModal_activeTab__NYwEI):hover {
  background: rgba(250, 250, 250, 0.15);
}
.ConnectWalletModal_activeTab__NYwEI {
  background: linear-gradient(89deg, #224dbd 0%, #3069ff 60.76%, #42ccd7 100%);

  padding: 8px 16px;
  border: none;
}
.ConnectWalletModal_currencyIcon__11o32 {
  width: auto;
}
.ConnectWalletModal_wallet__1ujGP {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  grid-gap: 7px;
  gap: 7px;
  border-radius: 10px;
  background: #273375;
  padding: 15px;
  transition: 0.3s;

  cursor: pointer;
  width: 100%;
}
.ConnectWalletModal_wallet__1ujGP:hover,
.ConnectWalletModal_wallet__1ujGP:has(div) {
  background: #19214a;
}
.ConnectWalletModal_icon__2oqi6 {
  width: 24px;
}
.ConnectWalletModal_name__Z8zJs {
  color: #fafafa;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.ConnectWalletModal_text__hGkHo {
  color: rgba(250, 250, 250, 0.72);
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-align: center;
}
.ConnectWalletModal_info__1gTTH {
  color: rgba(250, 250, 250, 0.7);
  text-align: center;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.ConnectWalletModal_highlight__uO-tA {
  color: rgba(250, 250, 250, 1);
}
.ConnectWalletModal_error__rGU3E {
  font-size: 14px;
  color: #fe595a;
}
@media only screen and (max-width: 991px) {
  .ConnectWalletModal_modal__AWpto {
    top: 50px;
  }
}
@media only screen and (max-width: 380px) {
  .ConnectWalletModal_tab__3z4s_ {
    font-size: 14px;
  }
  .ConnectWalletModal_currencyIcon__11o32 {
    width: 22px;
  }
}

.styles_sidebar__3IiGt {
  position: fixed;
  width: 265px;
  padding: 0 35px;
  padding-top: 50px;
  top: 101px;
  min-height: calc(100vh - 101px);

  z-index: 2;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.styles_sidebar__3IiGt::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 40%;
  right: 0;
  top: 60%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #343ab9;
  opacity: 0.8;
  -webkit-filter: blur(300.176px);
          filter: blur(300.176px);
  z-index: -1;
}
.styles_navItemContainer__2nWrI {
  display: flex;
  align-items: center;
  grid-gap: 7px;
  gap: 7px;
  padding: 0 15px;
  border-radius: 10px;
  transition: background 0.2s;
}
.styles_navItemContainer__2nWrI:not(.styles_navActive__SX4Wv):hover {
  background: rgba(255, 255, 255, 0.1);
}
.styles_navActive__SX4Wv {
  background: rgba(21, 60, 159, 0.8);
}
.styles_navItem__6OOMi {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #fafafa;
  padding: 12px 0;
}

.styles_icon__35go7 {
  margin-right: 7px;
}

.styles_button__2E_Jj {
  font-family: Helvetica Neue;
  font-weight: 500;
  line-height: 20px;
  color: var(--primary-color);
  border-radius: 25px;
  padding: 12px 16px 14px;
  margin-top: 30px;
  cursor: pointer;
  transition: opacity 0.2s;
}
.styles_button__2E_Jj:hover {
  opacity: 0.9;
}

.styles_referralContainer__3XBsD {
  width: 100%;
  margin: auto 0 45px;
}

.styles_referralButton__1OgKx .styles_button__2E_Jj {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 68px;
  background-image: radial-gradient(220px 190px at 89% 35%, #343ab955, transparent 150%),
    radial-gradient(220px 190px at 15% 60%, #343ab933, transparent 150%),
    linear-gradient(293.96deg, #12193b -26.79%, #141a42 36.61%, #004d49 100%);
  margin-top: 20px;
}

.styles_referralButton__1OgKx .styles_button__2E_Jj > div:nth-child(2) {
  font-size: 12px;
  margin-top: 2px;
}

@media only screen and (max-width: 991px) {
  .styles_sidebar__3IiGt {
    top: 165px;
    min-height: calc(100vh - 165px);
    -webkit-transform: translateX(-150%);
            transform: translateX(-150%);
    padding: 0 30px;
    padding-top: 30px;
    transition: 0.5s ease;
  }
  .styles_showSidebar__6oLca {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: 0.5s ease;
  }
}

.ConnectWalletButton_connectWalletButton__cq8Hd {
  width: -webkit-min-content;
  width: min-content;
  color: #fafafa;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border-radius: 25px;
  background: #3069ff;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  padding: 10px 16px;
  cursor: pointer;
  border: none;
  outline: none;
  white-space: nowrap;
  transition: opacity 0.2s;
}
.ConnectWalletButton_connectWalletButton__cq8Hd:hover {
  opacity: 0.9;
}
.ConnectWalletButton_icon__WaaKs {
  width: 14px;
}
@media only screen and (max-width: 380px) {
  .ConnectWalletButton_connectWalletButton__cq8Hd {
    font-size: 12px;
    padding: 6px 10px;
    grid-gap: 5px;
    gap: 5px;
  }
  .ConnectWalletButton_icon__WaaKs {
    width: 12px;
  }
}

.styles_notificationContainer__1Gb3y {
  background: #0c1232;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 357px;
  width: 100%;

  position: absolute;
  top: 7px;
  right: 15px;
  z-index: 5;
}
.styles_heading__1X2SV {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;

  letter-spacing: -0.02em;

  color: #ffffff;
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
  padding: 18px 15px;
}
.styles_notifications__13VSi {
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 12px;
  gap: 12px;
  height: 80vh;
  overflow-y: auto;
}

.styles_userWrapper__2MhN3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  width: 100%;
}
.styles_userAndnotification__CTnEZ {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.styles_userImage__3CZ-H {
  width: 40px;
  height: 40px;
  display: block;
  cursor: pointer;
}
.styles_userInfo__1zM4I {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;

  color: #fafafa;
}
.styles_userName__3OXIw {
  font-weight: 600;
  cursor: pointer;
}
.styles_time__3Uchs {
  opacity: 0.72;
}
.styles_buttonContainer__32Rge {
  display: flex;
  align-items: center;
  grid-gap: 3px;
  gap: 3px;
}
.styles_button__2UKfp {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  color: #303030;
  background: #fafafa;
  border-radius: 10px;
  padding: 8px 8px;
  cursor: pointer;
  min-width: 60px;
}
.styles_remove__NyUW_ {
  background: #ff5757;
}

.styles_image__Fuhsd {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
@media only screen and (max-width: 400px) {
  .styles_notificationContainer__1Gb3y {
    right: 0;
  }
  .styles_notifications__13VSi {
    padding: 15px 8px;
  }
  .styles_userInfo__1zM4I {
    font-size: 13px;
  }
  .styles_button__2UKfp {
    padding: 6px 4px;
    font-size: 12px;
  }
}

.styles_profileSettings__3estk {
  background: #0c1232;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 357px;
  width: 100%;
  position: absolute;
  top: 7px;
  right: 15px;
  z-index: 5;
}

.styles_header__ZM62j {
  padding: 18px 15px;
  display: flex;
  align-items: center;
  grid-gap: 25px;
  gap: 25px;
}
.styles_userImage__1Gs0K {
  width: 80px;
  height: 80px;
}
.styles_userName__2Wuzn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.styles_balanceAndAddress__9w_mh {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
  padding: 22px 15px;
}
.styles_balanceContainer__1Z3Oi {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.styles_balanceText__10m9j {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: rgba(250, 250, 250, 0.7);
}

.styles_balance__3sV_4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;

  letter-spacing: -0.02em;
  color: #fafafa;
}

.styles_xpBalance__1lz-y {
  font-size: 18px;
  text-shadow: 0px 0px 5px #4369cb;
}

.styles_text__1Oy_M {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;

  letter-spacing: -0.02em;

  color: #fafafa;
}
.styles_address__bM-db {
  background: rgba(250, 250, 250, 0.15);
  border-radius: 10px;
  padding: 5px;
}

.styles_itemContainer__3_Qv1 {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  padding: 22px 15px;
}

.styles_iconAndItem__1tFDw {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  cursor: pointer;
}

.styles_icon__1Jd9O {
  font-size: 20px;
  color: #fafafa;
}

.Header_header__1VCKf {
  position: fixed;
  z-index: 6;
  /* max-width: 1440px; */
  width: 100%;
  top: 0;
  display: grid;
  grid-template-columns: 230px 1fr 195px;
  align-items: center;
  background-color: #0c1232;
  padding: 22px 50px 24px;
  height: 101px;
}
.Header_logoContainer__gaEZM {
  width: -webkit-min-content;
  width: min-content;
  height: 54px;
  position: relative;
}
.Header_betaBadge__1OXnM {
  color: #ffb300;
  font-size: 10px;
  position: absolute;
  bottom: -6px;
  right: 0px;
  font-weight: bold;
}
.Header_inputContainer__vOT2J {
  background: rgba(250, 250, 250, 0.2);
  border-radius: 10px;
  padding-left: 42px;
  padding-right: 15px;
  max-width: 585px;
  position: relative;
  transition: background 0.2s;
}
.Header_inputContainer__vOT2J:hover,
.Header_inputContainer__vOT2J:has(.Header_input__1s5iR:focus) {
  background: rgba(250, 250, 250, 0.23);
}
.Header_search__ckDys {
  color: var(--primary-color);
  font-size: 21px;
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.Header_input__1s5iR {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: rgba(250, 250, 250, 0.72);
  padding: 12px 0;
  border: none;
  outline: none;
  background: none;
  width: 100%;
}
.Header_input__1s5iR::-webkit-input-placeholder {
  color: rgba(250, 250, 250, 0.72);
}
.Header_input__1s5iR::placeholder {
  color: rgba(250, 250, 250, 0.72);
}
.Header_userProfile__w1r9C {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 25px;
  gap: 25px;
}
.Header_userContainer__2khpQ {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  cursor: pointer;
  position: relative;
}
.Header_icon__2vvCF {
  display: grid;
  place-items: center;
  cursor: pointer;
}
.Header_notification__2T0pM {
  position: relative;
}
.Header_notification__2T0pM::before {
  display: block;
  content: " ";
  position: absolute;
  top: 0;
  right: 4px;
  width: 7px;
  height: 7px;
  background: #3069ff;
  border-radius: 50%;
  border: 1px solid #fafafa;
}
.Header_userImage__2FPSc {
  width: 45px;
  height: 45px;
}
.Header_arrow__1S-AG {
  font-size: 21px;
  color: var(--primary-color);
}
.Header_dropdowns__2lDmy {
  position: absolute;
  top: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 1;
  min-width: 100px;
  background-color: var(--primary-bg);
  border: 1px solid rgba(250, 250, 250, 0.2);
  padding: 15px;

  border-radius: 10px;
}
/* .userContainer:hover .dropdowns {
  display: flex;
} */
.Header_text__G3J9D {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: rgba(250, 250, 250, 0.72);
}
.Header_hamburger__uB-MN {
  display: none;
  color: var(--primary-color);
  font-size: 24px;
  cursor: pointer;
}
.Header_close__1B9-H {
  color: var(--primary-color);
  font-size: 24px;
  cursor: pointer;
}

@media only screen and (max-width: 1199px) {
  .Header_header__1VCKf {
    grid-template-columns: auto 1fr auto;
    grid-gap: 60px;
    gap: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .Header_header__1VCKf {
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    gap: 10px;
    height: 165px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .Header_searchContainer__3PSgw {
    order: 3;
    grid-column: 1/-1;
    align-self: center;
  }
  .Header_inputContainer__vOT2J {
    margin: 0 auto;
    max-width: 400px;
    width: 100%;
  }

  .Header_userProfile__w1r9C {
    order: 2;
    grid-gap: 20px;
    gap: 20px;
  }
  .Header_userContainer__2khpQ {
    grid-gap: 8px;
    gap: 8px;
  }
  .Header_dropdowns__2lDmy {
    top: 40px;
  }
  .Header_arrow__1S-AG {
    font-size: 16px;
  }
  .Header_userImage__2FPSc {
    width: 35px;
  }
  .Header_hamburger__uB-MN {
    display: block;
  }
}
@media only screen and (max-width: 520px) {
}
@media only screen and (max-width: 380px) {
  .Header_logo__3_SJs {
    width: 70px;
  }
  .Header_inputContainer__vOT2J {
    padding-left: 30px;
    padding-right: 15px;
  }
  .Header_input__1s5iR {
    font-size: 13px;
  }
  .Header_search__ckDys {
    font-size: 16px;
  }
  .Header_userProfile__w1r9C {
    grid-gap: 14px;
    gap: 14px;
  }
}

.search_searchingContainer__2UGjt {
  position: absolute;
  left: 0;
  background: #0c1232;
  border-radius: 8px;
  min-width: 100%;

  z-index: 1;
}

.search_searchTitle__2Q_uJ {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: rgba(250, 250, 250, 0.7);
  padding: 15px;
}
.search_details__YGGZk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
  padding: 5px 0;
  padding: 15px;
  cursor: pointer;
}

.search_imgAndName__VoqGd {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.search_moonBirds__1lD1H {
  width: 30px;
  height: 30px;
  border-radius: 8px;
}
.search_nameAndItems__2ElDi {
  display: flex;
  flex-direction: column;
  grid-gap: 0px;
  gap: 0px;
}
.search_name__1ijh6 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;

  color: #fafafa;
  cursor: pointer;
}
.search_items__3TaUC {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;

  color: rgba(250, 250, 250, 0.72);
}

.styles_modal__1XamP {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 92;
  width: 100%;
  display: flex;
  justify-content: center;
}
.styles_overlay__3UTWn {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(9, 13, 36, 0.67);
  z-index: 91;
  cursor: pointer;
}

.CheckBox_container__NNDlh {
  display: flex;
  align-items: center;

  color: #ffffff;
}

.CheckBox_container__NNDlh input[type="checkbox"] {
  display: none;
}

.CheckBox_checkmark__1t2Jy {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #42ccd7;
  margin-right: 5px;
  position: relative;

  cursor: pointer;
}

.CheckBox_container__NNDlh input[type="checkbox"]:checked + .CheckBox_checkmark__1t2Jy:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;

  background: #42ccd7;
  border-radius: 50%;
}
.CheckBox_text__3JQgf {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.styles_wrapper__1hLyJ {
  display: flex;
  flex-wrap: wrap;

  grid-gap: 8px;

  gap: 8px;
  width: 100%;
  padding-left: 25px;
  padding-top: 15px;
}

.styles_text__2YgB2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #fafafa;
}
.styles_inputContainerAndUsdValue__1sq1s {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.styles_inputContainer__206d7 {
  background: rgba(250, 250, 250, 0.1);
  border: 1px solid rgba(250, 250, 250, 0.72);
  border-radius: 10px;
  width: 100%;
  max-width: 291px;
  display: grid;
  grid-template-columns: 1fr 100px;
}
.styles_input__1NZv3,
.styles_valueText__35Ufa {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
  color: #fafafa;
}
.styles_input__1NZv3 {
  padding: 4px 10px;
  background: none;
  border: none;
  outline: none;
  width: 100%;
  text-transform: none;
}
.styles_valueTypeContainer__iW2g3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 4px 10px;
  border-left: 1px solid rgba(250, 250, 250, 0.72);
}
.styles_arrow__11O3J {
  color: #fff;
  cursor: pointer;
}
.styles_allValueText__1jlN7 {
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
  position: absolute;
  top: 35px;
  left: 0;
  width: 100%;
  padding: 10px;
  background: rgba(250, 250, 250, 0.1);
  border-radius: 10px;
}
.styles_allValueText__1jlN7 .styles_valueText__35Ufa {
  cursor: pointer;
}
.styles_usdValue__3mUM_ {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;

  color: rgba(250, 250, 250, 0.72);
}
.styles_priceNotValid__247mO {
  color: #ff0000;
}

.styles_addPostContainer__1HTv_ {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 50;
}
.styles_addPost__1UUdo {
  border-radius: 0px 0px 20px 0px;
  background: #181e3c;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.7);

  max-width: 1143px;
  position: relative;
  margin: 0 auto;
}

.styles_titleContainer__39wG6 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #242c56;
  border-radius: 20px 20px 0px 0px;
  padding: 15px 14px;
  position: relative;
}

.styles_title__Fn4da {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 125%;
  color: #fafafa;
}
.styles_wrapper__3t4JV {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;

  min-height: 554px;
  max-height: 90vh;
  overflow-y: auto;
}
.styles_image__IYBVP {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 0px 0px 0px 20px;
}
.styles_details__3s-jA {
  display: flex;
  flex-direction: column;
}
.styles_userContainer__1ML80 {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  padding: 18px;
}
.styles_text__PYi0V {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;

  color: #fafafa;
}
.styles_editText__11L1z {
  font-weight: 400;
}
.styles_textArea__2weo1 {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
  resize: none;
}
.styles_profile__3Adef {
  width: 32px;
  height: 32px;
}
.styles_edits__29q5U,
.styles_tagPeople__2cUMO,
.styles_listForSaleContainer__QTvkv {
  display: flex;
  padding: 18px;
  grid-gap: 6px;
  gap: 6px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
  min-height: 130px;
}
.styles_edit__19SL3 {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.styles_tagPeople__2cUMO {
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
  min-height: auto;
}
.styles_userIcon__3SsaT {
  color: #fff;
  font-size: 22px;
}
.styles_listForSaleContainer__QTvkv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.styles_listForSale__2i7uf {
  display: flex;
  grid-gap: 6px;
  gap: 6px;
}
.styles_checkboxContainer__1tFSd {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  padding-top: 6px;
  padding-left: 25px;
}
.styles_btnDiv__1n9mo {
  padding: 18px;
  display: grid;
  grid-template-columns: 147px 1fr;
  align-items: center;
  grid-gap: 20px 20px;
  gap: 20px 20px;
  margin-top: auto;
}
.styles_invertBtn__jT_Vu {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 10px;
  height: 46px;
}
.styles_invertBtn__jT_Vu:hover {
  background-color: white;
  color: black;
}
.styles_button__1NltJ {
  border-radius: 10px;
}
.styles_backButton__1EFUP {
  position: absolute;
  top: 30px;
  left: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  z-index: 100;
  color: #fafafa;
  font-size: 16px;
  background: #0c1232;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  grid-gap: 2px;
  gap: 2px;
  align-items: center;
  padding: 5px 7px;
  padding-right: 10px;
}
.styles_backText__2UwWQ {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;

  text-align: center;
  color: #ffffff;
}
.styles_back__2kQug {
  font-size: 20px;
}
.styles_buttonLoader__1HrCR {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 991px) {
  .styles_wrapper__3t4JV {
    grid-template-columns: 1fr;
    grid-gap: 25px;
    gap: 25px;
  }
  .styles_addPost__1UUdo {
    border-radius: 0 0 20px 20px;
  }
  .styles_image__IYBVP {
    border-radius: 0;
  }
  .styles_close__1DTz3 {
    color: #181e3c;
  }
}
@media only screen and (max-width: 520px) {
  .styles_btnDiv__1n9mo {
    padding: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px 10px;
    gap: 10px 10px;
  }
  .styles_image__IYBVP {
    border-radius: 0px;
  }
  .styles_userContainer__1ML80,
  .styles_edits__29q5U,
  .styles_tagPeople__2cUMO,
  .styles_buttonDiv__1XbsU.styles_listForSale__2i7uf {
    padding: 18px 10px;
  }
}

.styles_wrapper__3i3P5 {
}
.styles_header__3_XsG {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 18px;
}
.styles_heading__3fkuG {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 125%;
  color: #ffffff;
}
.styles_arrow__18Pup {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
.styles_infoContainer__1YWEq,
.styles_description__2tW5O {
  padding: 22px 18px;

  border-top: 1px solid rgba(250, 250, 250, 0.2);
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
}
.styles_infoContainer__1YWEq {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 18px;
  gap: 18px;
}
.styles_description__2tW5O {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}
.styles_info__zs6cC {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}
.styles_propertyContainer__nP6oc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  gap: 12px;
  padding: 18px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
}
.styles_propertyBox__31T7r {
  background: rgba(66, 204, 215, 0.11);
  border: 1px solid #42ccd7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  padding: 10px 8px;
}
.styles_text__2HR8V {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;

  color: #fafafa;
}
.styles_title__2XGn8 {
  color: rgba(250, 250, 250, 0.72);
}
.styles_address__2WtYN {
  color: #42ccd7;
}
@media only screen and (max-width: 520px) {
  .styles_info__zs6cC,
  .styles_titleContainer__1_ueb {
    grid-template-columns: 1fr 0.8fr 1fr;
  }
  .styles_text__2HR8V {
    font-size: 13px;
  }
  .styles_header__3_XsG,
  .styles_infoContainer__1YWEq {
    padding: 18px 15px;
  }
}
@media only screen and (max-width: 400px) {
  .styles_info__zs6cC,
  .styles_titleContainer__1_ueb {
    grid-template-columns: 1fr 0.7fr 1fr;
  }
  .styles_text__2HR8V {
    font-size: 12px;
  }
  .styles_header__3_XsG,
  .styles_infoContainer__1YWEq {
    padding: 18px 12px;
  }
}

.styles_descriptionContainer__3UtLH {
  position: fixed;
  right: 0;
  top: 101px;
  max-width: 358px;
  width: 100%;
  max-height: calc(100vh - 101px);
  height: calc(100vh - 101px);
  overflow: auto;
  background: rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(15.5px);
          backdrop-filter: blur(15.5px);
  -webkit-transform: translate(150%);
          transform: translate(150%);
  transition: 0.3s;
}
.styles_descriptionWrapper__3oGrF {
  -webkit-transform: translate(0%);
          transform: translate(0%);
  transition: 0.3s;
}
.styles_close__37gUz {
  color: #fafafa;

  position: absolute;
  top: 10px;
  left: 18px;
  font-size: 30px;
  cursor: pointer;
  display: none;
}
.styles_nameId__3h1Vn {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  padding: 15px 18px;
  padding-top: 20px;
}
.styles_name__3eA7N {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.styles_nameHighlight__2WPz3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 120%;

  color: #42ccd7;
}
@media only screen and (max-width: 1399px) {
  .styles_descriptionContainer__3UtLH {
    padding-top: 30px;
    background: #181e3c;
  }

  .styles_close__37gUz {
    display: block;
  }
}
@media only screen and (max-width: 991px) {
  .styles_descriptionContainer__3UtLH {
    top: 165px;
  }
}
@media only screen and (max-width: 520px) {
  .styles_close__37gUz {
    left: 15px;
  }
  .styles_nameId__3h1Vn {
    padding: 15px 15px;
  }
}
@media only screen and (max-width: 400px) {
  .styles_close__37gUz {
    left: 12px;
  }
  .styles_nameId__3h1Vn {
    padding: 15px 12px;
  }
}

.styles_allNft__2s3V- {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110.18px, 1fr));
  grid-gap: 8px;
  gap: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 24px;
  margin-top: 15px;
}
.styles_imageContainer__3V02y {
  cursor: pointer;
  border-radius: 12px;
}
.styles_active__2czTf {
  border: 3px solid #224dbd;
}
.styles_image__3XGtH {
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
  display: block;
}

@media only screen and (max-width: 991px) {
  .styles_descriptionContainer__NevJB {
    top: 165px;
    max-height: calc(100vh - 165px);
    height: calc(100vh - 165px);
  }
}
@media only screen and (max-width: 575px) {
  .styles_allNft__2s3V- {
    padding: 15px;
    grid-gap: 4px;
    gap: 4px;
  }
}

.styles_chooseWallet__2luN4 {
  background: #2f3244;
  border-radius: 16px;
  padding: 12px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.styles_allWallet__3iV75 {
  position: absolute;
  left: 0;
  width: 100%;
  top: 70px;
  padding: 15px;
  background: #2f3244;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 8px;
}
.styles_wallet__374Di {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.styles_imageContainer__ZxnL7 {
  height: 40px;
  width: 40px;
}

.styles_nameAndAddress__nLKzT {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.styles_name__4Fnqh {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #fafafa;
}

.styles_address__1IIi3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  color: #808080;
}
.styles_arrow__A48vM {
  color: #fafafa;
  font-size: 22px;
  cursor: pointer;
}
.styles_allWallet__3iV75 {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}
.styles_allWallet__3iV75 .styles_wallet__374Di {
  cursor: pointer;
}
.styles_allWallet__3iV75 .styles_imageContainer__ZxnL7 {
  height: 32px;
  width: 32px;
}

.styles_overlay__1i7tm {
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.6;
  z-index: 92;
  width: 100%;
  height: 100%;
}
.styles_smallModal__26KXm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  background-color: var(--primary-bg);
  padding: 32px 24px 24px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 93;
  text-align: center;
}
.styles_smallModal__26KXm p {
  line-height: 140%;
  color: #fafafa;
}

.styles_btnDiv__1zbNM {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.styles_invertBtn__3gPQ3 {
  height: 37px;
  color: var(--primary-color);
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid white;
  padding: 0px 45px;
}
.styles_invertBtn__3gPQ3:hover {
  background-color: white;
  color: black;
}
.styles_button__1xhkT {
  height: 37px;
  border-radius: 10px;
  padding: 0px 45px;
}

.styles_progressWrapper__3DOPv {
  height: 4px;
  border-radius: 200;
  overflow: hidden;
}

.styles_progressBar__Cv8Nn {
  height: 100%;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 200;
  transition: background-color 0.3s ease-out;
}

.styles_progress__3aTyM {
  height: 100%;
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 200;
  transition: width 0.3s ease-out;
}

.styles_createNewPostContainer__28CLU {
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 18px;
  gap: 18px;
  padding-top: 40px;
}

.styles_title__1ENYl {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #fafafa;
}

.styles_text__1Z5ln {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
}

.styles_chooseWallet__3Fh3R {
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
}

.styles_button__9nuU_ {
  border-radius: 10px;
}

.styles_buttonActive__25nxc {
  cursor: pointer;
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .styles_chooseWallet__3Fh3R {
    grid-template-columns: 1fr;
  }
}

.Input_inputContainer__1BnqC {
  display: flex;
  flex-direction: column;
  padding: 7px 0;
  width: 100%;
}
.Input_label__z-LP7 {
  display: block;
  padding-bottom: 5px;
  cursor: pointer;
  text-transform: capitalize;
}
.Input_text__33-pa {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;

  color: #fafafa;
}
.Input_input__3PNbO {
  background-color: transparent;
  display: block;
  width: 100%;
  margin: 0 auto;
  border: 1.5px solid rgba(250, 250, 250, 0.72);
  border-radius: 16px;
  padding: 15px 20px;
  outline: none;
}
@media only screen and (max-width: 520px) {
  .Input_input__3PNbO {
    font-size: 14px;
    padding: 15px 10px;
  }
}

.SelectBanner_overlay__EdFzI {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 88;
}

.SelectBanner_modalWrapp__1xRPG {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90%;
  max-width: 700px;
  max-height: 490px;
  overflow-y: auto;
  z-index: 90;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 30px;
  border-radius: 8px;
  height: 90%;
  background-color: var(--primary-bg);
}

.SelectBanner_modalHeader__2-mCi {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 5px;
}

.SelectBanner_modalHeader__2-mCi h3 {
  color: white;
  font-size: 22px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.SelectBanner_modalHeader__2-mCi svg {
  min-width: 30px;
  max-width: 30px;
  object-fit: contain;
  cursor: pointer;
}

.SelectBanner_photosDiv__cnUuz {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  grid-auto-rows: 1fr;
  width: 100%;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
  max-height: 320px;
  height: 100%;
  padding-right: 10px;
  overflow-y: auto;
}

.SelectBanner_photosDiv__cnUuz button {
  width: 100%;
  aspect-ratio: 1/1;
  background-color: transparent;
  cursor: pointer;
}

.SelectBanner_photosDiv__cnUuz button img {
  border-radius: 8px;
  border: 4px solid transparent;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
  height: 100%;
}

.SelectBanner_button__1ugrp {
  width: 150px;
}

.SelectProfilePicture_overlay__2bU5p {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 88;
}
.SelectProfilePicture_modalWrapp__3v4KF {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90%;
  max-width: 700px;
  max-height: 490px;
  overflow-y: auto;
  z-index: 90;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 30px;
  border-radius: 8px;
  height: 90%;
  background-color: var(--primary-bg);
}
.SelectProfilePicture_modalHeader__ghPLr {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 5px;
}
.SelectProfilePicture_modalHeader__ghPLr h3 {
  color: white;
  font-size: 22px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}
.SelectProfilePicture_modalHeader__ghPLr svg {
  min-width: 30px;
  max-width: 30px;
  object-fit: contain;
  cursor: pointer;
}
.SelectProfilePicture_photosDiv__2Hz4K {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  grid-auto-rows: 1fr;
  width: 100%;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
  max-height: 320px;
  height: 100%;
  padding-right: 10px;
  overflow-y: auto;
}
.SelectProfilePicture_photosDiv__2Hz4K button {
  width: 100%;
  aspect-ratio: 1/1;
  background-color: transparent;
  cursor: pointer;
}
.SelectProfilePicture_photosDiv__2Hz4K button img {
  border-radius: 8px;
  border: 4px solid transparent;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
  height: 100%;
}
.SelectProfilePicture_button__3yPxO {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 10px 45px;
  cursor: pointer;
}

.styles_profileWrapper__3ghJ2 {
  padding-top: 50px;
  padding-left: 50px;
  position: relative;
  z-index: 2;
  margin-top: 101px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 45px;
  gap: 45px;
}

.styles_profileWrapper__3ghJ2::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 100%;
  right: 0;
  top: 0;

  background: #343ab9;
  opacity: 0.3;
  -webkit-filter: blur(300.176px);
          filter: blur(300.176px);
  z-index: -1;
}

.styles_tabsContainer__3DK-L {
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  gap: 6px;
}

.styles_profile__WMgvG {
  background: rgba(250, 250, 250, 0.1);
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  margin: 0 auto;
}

.styles_bannerContainer__1U4VI {
  padding: 20px 0;
}

.styles_bannerImageContainer__2cQEh {
  width: 100%;
  position: relative;
}

.styles_banner__3YKwj {
  width: 100%;
  min-height: 88px;
  max-height: 500px;
  object-fit: contain;
}

.styles_imageInput__1eV1Q {
  display: none;
}

.styles_editWrapper__1Rz1n {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
}

.styles_editIcon__NqmlL {
  font-size: 28px;
  color: var(--primary-color);
}

.styles_text__33huW {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #fafafa;
  padding-bottom: 8px;
}

.styles_user__y6zys {
  width: 100px;
  height: 90px;
  cursor: pointer;
}

.styles_warningBorder__2SPqm {
  border: 1.5px solid #fe595a;
}

.styles_duplicateUsername__Oj_-d {
  font-size: 14px;
  color: #fe595a;
}

.styles_defaultAddressContainer__1SrOw {
  display: flex;
  flex-direction: column;
  padding: 7px 0;
  width: 100%;
}

.styles_label__3rZO1 {
  display: block;
  padding-bottom: 5px;
  cursor: pointer;
  text-transform: capitalize;
}

.styles_inputContainer__3RbC0 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 25px;
  border: 1.5px solid rgba(250, 250, 250, 0.72);

  border-radius: 16px;

  position: relative;
  cursor: pointer;
}

.styles_inputContainer__3RbC0:hover {
  border-radius: 16px 16px 0 0;
  border-bottom: none;
}

.styles_addressContainer__29OZG {
  position: absolute;
  left: -1px;
  top: 53px;
  z-index: 2;
  width: calc(100% + 2px);

  border: 1.5px solid rgba(250, 250, 250, 0.72);
  border-top: none;
  border-radius: 0 0 16px 16px;

  padding: 15px;
  background: var(--primary-bg);

  display: none;
}

.styles_address__3502i {
  cursor: pointer;
  padding: 5px 0;
}

.styles_inputContainer__3RbC0:hover .styles_addressContainer__29OZG {
  display: flex;
  flex-direction: column;
}

.styles_addressContainer__29OZG:hover {
  display: flex;
  flex-direction: column;
}

.styles_inputContainer__3RbC0:hover .styles_arrow__1QGHw {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.styles_input__1aJ6c {
  background-color: transparent;
  display: block;
  width: 100%;
  margin: 0 auto;

  padding: 15px 20px;
  outline: none;
  border: none;
}

.styles_textAreaContainer__2LuPD {
  padding: 5px 0;
}

.styles_textArea__3uCBT {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #fafafa;
  background-color: transparent;
  width: 100%;
  border: 1.5px solid rgba(250, 250, 250, 0.72);
  border-radius: 16px;
  padding: 15px 20px;
}

.styles_arrow__1QGHw {
  color: var(--primary-color);
  font-size: 18px;
}

.styles_button__1mAMR {
  width: 100%;
  padding: 15px 25px;
  margin-top: 20px;
}

@media only screen and (max-width: 991px) {
  .styles_profileWrapper__3ghJ2 {
    margin-top: 165px;
  }
}
@media only screen and (max-width: 767px) {
  .styles_profileWrapper__3ghJ2 {
    grid-template-columns: 1fr;
    padding-left: 0;
  }
  .styles_tabsContainer__3DK-L {
    padding: 0 40px;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 25px;
    gap: 25px;
    align-items: center;
  }
}
@media only screen and (max-width: 520px) {
  .styles_profile__WMgvG {
    padding: 30px 12px;
    width: 100%;
  }
  .styles_tabsContainer__3DK-L {
    justify-content: center;
  }
  .styles_text__33huW {
    font-size: 14px;
  }
}
@media only screen and (max-width: 450px) {
  .styles_inputContainer__3RbC0 {
    padding-right: 10px;
  }
  .styles_input__1aJ6c {
    font-size: 14px;
    padding: 15px 10px;
  }
  .styles_textArea__3uCBT {
    padding: 15px 10px;
  }
  .styles_addressInput__R-u7D {
    font-size: 10px;
  }
  .styles_address__3502i {
    font-size: 10px;
  }
  .styles_addressContainer__29OZG {
    top: 43px;
  }
}

.SingleUser_topUser__1STYT {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  background: #0c1232;
  border-radius: 10px;
  padding: 15px;
}
.SingleUser_userImage__22glm {
  width: 150px;
  height: 150px;
}
.SingleUser_userName__WgIhb {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: #fafafa;
}
.SingleUser_addFriend__2TU5T {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  color: #303030;
  background: #fafafa;
  border-radius: 10px;
  padding: 12px 15px;
  cursor: pointer;
  min-width: 130px;
  transition: opacity 0.2s;
}
.SingleUser_addFriend__2TU5T:hover {
  opacity: 0.9;
}

.SingleNft_nftContainer__2-WAq {
  aspect-ratio: 1;
  overflow: hidden;
}

.SingleNft_nftImage__1t-bP {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

.Explore_explore__2dk8O {
  padding: 60px 0;
}
.Explore_header__34dUI {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  padding-bottom: 20px;
}

.Explore_title__3hhoT {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.Explore_button__3EajC {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 143%;
  text-align: center;
  color: #fafafa;
  border: 1px solid #fafafa;
  border-radius: 10px;
  padding: 12px 24px;
  cursor: pointer;
  transition: opacity 0.2s;
}
.Explore_button__3EajC:hover {
  opacity: 0.9;
}

.Explore_wrapper__1wl1N {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  padding-bottom: 50px;
}

.Explore_nftContainer__27NW7 {
  aspect-ratio: 1;
  overflow: hidden;
}

.Explore_nftImage__3Pkxc {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.Explore_topUser__17s0- {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  background: #0c1232;
  border-radius: 10px;
  padding: 15px;
}
.Explore_userImage__2A4gp {
  width: 80px;
  height: 80px;
}
.Explore_userName__138du,
.Explore_category__SHDt0 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: #fafafa;
}
.Explore_addFriend__K_2zg {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  color: #303030;
  background: #fafafa;
  border-radius: 10px;
  padding: 12px 22px;
  cursor: pointer;
  min-width: 130px;
  transition: opacity 0.2s;
}
.Explore_addFriend__K_2zg:hover {
  opacity: 0.9;
}

.Explore_collection__1eKD4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Explore_category__SHDt0 {
  text-align: center;
  padding-top: 5px;
}
.Explore_image__1Rcoo {
  width: 100%;
}
.Explore_info__13Q1y {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: rgba(250, 250, 250, 0.7);
}
@media only screen and (max-width: 520px) {
  .Explore_header__34dUI {
    flex-direction: column;
    align-items: flex-start;
  }
  .Explore_button__3EajC {
    padding: 8px 20px;
    font-size: 12px;
    margin-left: auto;
  }
  .Explore_title__3hhoT {
    font-size: 28px;
  }
}

.FindFriends_findFriendsContainer__1Tvqp {
  padding: 40px 0;
  padding-bottom: 70px;
}

.FindFriends_title__1AJEf {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  letter-spacing: -0.02em;
  text-align: left;
  width: 100%;
  color: #ffffff;

  padding-bottom: 20px;
}
.FindFriends_wrapper__1Zr4P {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(267px, 1fr));

  grid-gap: 15px;

  gap: 15px;

  width: 100%;
}

@media only screen and (max-width: 520px) {
  .FindFriends_title__1AJEf {
    font-size: 28px;
  }
}

.Nfts_nfts__3Q0Sk {
  padding: 40px 0;
  padding-bottom: 70px;
}
.Nfts_title__3XKB5 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  letter-spacing: -0.02em;
  text-align: left;
  width: 100%;
  color: #ffffff;
  padding: 20px 0;
}
.Nfts_wrapper__2G_5j {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));

  grid-gap: 10px;

  gap: 10px;

  width: 100%;
  overflow-y: auto;
}

.styles_wrapper__13czl {
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(9px);
          backdrop-filter: blur(9px);
  border-radius: 20px;
}
.styles_header__3PjN_ {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 18px;
}
.styles_heading__1fsNR {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 125%;
  color: #ffffff;
}
.styles_arrow__1bvg3 {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
.styles_infoContainer__7pj72 {
  padding: 18px;
  display: flex;
  flex-direction: column;
  grid-gap: 18px;
  gap: 18px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
}

.styles_info__3sLpT,
.styles_titleContainer__Vz7Jg {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  gap: 5px;
}
.styles_propertyContainer__3TMzO {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(154px, 1fr));
  grid-gap: 12px;
  gap: 12px;
  padding: 18px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
}
.styles_propertyBox__277IY {
  background: rgba(66, 204, 215, 0.11);
  border: 1px solid #42ccd7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  padding: 10px 8px;
}
.styles_text__3_Hw2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;

  color: #fafafa;
}
.styles_title__1qxhX {
  color: rgba(250, 250, 250, 0.72);
}
.styles_address__1PwYv {
  color: #42ccd7;
}
@media only screen and (max-width: 520px) {
  .styles_info__3sLpT,
  .styles_titleContainer__Vz7Jg {
    grid-template-columns: 1fr 0.8fr 1fr;
  }
  .styles_text__3_Hw2 {
    font-size: 13px;
  }
  .styles_header__3PjN_,
  .styles_infoContainer__7pj72 {
    padding: 18px 15px;
  }
}
@media only screen and (max-width: 400px) {
  .styles_info__3sLpT,
  .styles_titleContainer__Vz7Jg {
    grid-template-columns: 1fr 0.7fr 1fr;
  }
  .styles_text__3_Hw2 {
    font-size: 12px;
  }
  .styles_header__3PjN_,
  .styles_infoContainer__7pj72 {
    padding: 18px 12px;
  }
}

.styles_wrapper__2oPbC {
  width: 100%;
  position: relative;
  z-index: 2;
  padding-top: 40px;
}
.styles_postDetails__3jQww {
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-gap: 25px;

  gap: 25px;
}
.styles_header__eTfEx {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  position: relative;
}
.styles_backArrow__18rdP {
  color: #fff;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: -60px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
}
.styles_title__3mbVg {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.styles_price__2eMnw {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #fafafa;
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  grid-gap: 3px;
  gap: 3px;
  cursor: pointer;
}
.styles_ethIcon__3nZAn {
  width: 11.4px;
  height: 19.2px;
}
.styles_name__sPEZn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  color: #42ccd7;
  padding-bottom: 30px;
}
.styles_imageContainer__MqEOv {
  border-radius: 40.5128px;
  max-width: 553px;
}
.styles_image__37nK5 {
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 40.5128px;
}
.styles_detailsContainer__12tMx {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  max-width: 557px;
  width: 99%;
}

@media only screen and (max-width: 1280px) {
  .styles_postDetails__3jQww {
    max-width: calc(100% - 150px);
  }
}
@media only screen and (max-width: 1199px) {
  .styles_postDetails__3jQww {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 0 auto;
    max-width: 100%;
  }
}
@media only screen and (max-width: 520px) {
  .styles_backArrow__18rdP {
    color: #fff;
    font-size: 30px;
    position: absolute;
    top: -15px;
    left: 0;
    cursor: pointer;
  }

  .styles_title__3mbVg {
    font-size: 28px;
  }
  .styles_heading__m1p0k {
    font-size: 18px;
  }
  .styles_price__2eMnw {
    font-size: 13px;
  }
  .styles_name__sPEZn {
    font-size: 18px;
  }
}

.styles_uploadingWrapper__2Hegs {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
}
.styles_uploading__2p6rA {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;
}
.styles_imageAndName__3mDDe,
.styles_cancelContainer__2DTjY {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.styles_cancelContainer__2DTjY {
  cursor: pointer;
}
.styles_image__3l1Pe {
  width: 31px;
  height: 31px;
  border-radius: 2.1385px;
}
.styles_text__2fPKZ {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #fafafa;
}

.styles_warning__1ZJqn,
.styles_close__2N0ah {
  color: #ff5757;
}
.styles_close__2N0ah {
  cursor: pointer;
}

.styles_whotoFollow__jpvz3 {
  background: #0c1232;
  border-radius: 20px;
  padding: 15px 20px;
  max-width: 326px;
  width: 100%;
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 1;
}

.styles_title__3ANFv {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 125%;
  color: #ffffff;
  padding-bottom: 10px;
}
.styles_userWrapper__7cxVo {
  max-height: 80vh;
  overflow-y: auto;
}
.styles_userContainer__1W_zt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.styles_uerImageAndName__1BAIC {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.styles_userImage__JdpQK {
  width: 50px;
  height: 50px;
}
.styles_userName__2_I71 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #fafafa;
  cursor: pointer;
}
.styles_addFriend__3r-1g {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  color: #303030;
  background: #fafafa;
  border-radius: 10px;
  padding: 8px 8px;
  cursor: pointer;
  min-width: 100px;
  transition: opacity 0.2s;
}
.styles_addFriend__3r-1g:hover {
  opacity: 0.9;
}
.styles_showMore__3QG94 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #42ccd7;
  cursor: pointer;
  padding-top: 10px;
}
@media only screen and (max-width: 1199px) {
  .styles_whotoFollow__jpvz3 {
    display: none;
  }
  .styles_showWhotoFollow__2BdVP {
    display: block;
  }
}
@media only screen and (max-width: 520px) {
  .styles_whotoFollow__jpvz3 {
    right: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
@media only screen and (max-width: 400px) {
  .styles_whotoFollow__jpvz3 {
    padding: 20px 10px;
  }
}

.styles_heading__2IYmV {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 125%px;
  text-align: left;

  /* Continent Full Moon */

  color: #fafafa;
}

.styles_text__1xiWM {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: rgba(250, 250, 250, 0.72);
}

.styles_button__2Icar {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 7px 45px;
  margin-top: 30px;
  cursor: pointer;
}

.styles_wrapper__2rEA5 {
  background: #0c1232;
  border-radius: 8px;
  width: 418px;
  padding: 30px;
  padding-bottom: 20px;
}
.styles_titleContainer__vY0Pz {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}
.styles_title__378rI {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #fafafa;
}
.styles_close__2yrmS {
  color: #fafafa;
  font-size: 22px;
  cursor: pointer;
}

.styles_arrow__2bw7Y {
  font-size: 18px;
  color: #fafafa;
  cursor: pointer;
}
.styles_socials__1AZKI {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 0.5px solid rgba(250, 250, 250, 0.72);
}
.styles_socials__1AZKI:last-child {
  border: none;
}
.styles_nameAndIcon__1HVxt {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.styles_name__t2Nln {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;

  color: #fafafa;
}
.styles_socialIcon__2ObcV {
  width: 20px;
  height: 19.5px;
}

@media only screen and (max-width: 520px) {
  .styles_wrapper__2rEA5 {
    width: 90%;
    padding: 25px 18px;
    padding-bottom: 18px;
  }
}

.styles_commentContainer__LCyKW {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 0;
}

.styles_aboutPost__3yACE {
  display: flex;
  align-items: center;

  grid-gap: 10px;

  gap: 10px;
}
.styles_userImage__o4jtq {
  width: 32px;
  height: 32px;
}

.styles_commentAndUserName__22Qaf {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}
.styles_text__17uVO {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: var(--primary-color);
}
.styles_comment__LeDOp {
  font-weight: 400;
}

.styles_timeAndReply__11hGB {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.styles_time__K50Uv {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
}
.styles_replayButton__3y3QL {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}
.styles_like__2EEVM {
  display: flex;
  align-items: center;
  grid-gap: 2px;
  gap: 2px;
}
.styles_icon__2xdRO {
  width: 20px;
  height: 17.8px;
  cursor: pointer;
}
.styles_number__Pl7jB {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.72);
  margin-top: 1px;
}
@media only screen and (max-width: 400px) {
  .styles_aboutPost__3yACE {
    grid-gap: 6px;
    gap: 6px;
  }
  .styles_commentAndUserName__22Qaf {
    grid-gap: 6px;
    gap: 6px;
  }
  .styles_text__17uVO {
    font-size: 14px;
  }

  .styles_icon__2xdRO {
    width: 18px;
    height: 15.4px;
    cursor: pointer;
  }
  .styles_number__Pl7jB {
    font-size: 12px;
  }
  .styles_time__K50Uv {
    font-size: 12px;
  }
  .styles_replayButton__3y3QL {
    font-size: 12px;
  }
}

.styles_overlay__1hgNP {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 88;
  cursor: pointer;
}
.styles_close__1Bhup {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 90;
  color: #fff;
  font-size: 32px;
}
.styles_wrapper__1AFyX {
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  background: #20284d;

  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  position: absolute;
  z-index: 90;
  top: 200px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);

  width: 100%;
  max-width: 1143px;
  max-height: 675px;
}
.styles_postImage__ZpCnW {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px 0 0 20px;
}

.styles_header__DS9ec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.styles_userContainer__1u7LN {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.styles_userImg__1N5lv {
  width: 49px;
  height: 44.1px;
  display: block;
}
.styles_text__1_qH8 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: var(--primary-color);
}
.styles_subText__1kPm9 {
  font-weight: 400;
}
.styles_more__2cT4v {
  color: var(--primary-color);
  font-size: 24px;
}
.styles_informationContainer__2jcZA {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px 20px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
}
.styles_idAndbutton__2MR1E {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}
.styles_button__2lwrF {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #42ccd7;
  cursor: pointer;
}
.styles_aboutPost__3EupT {
  display: flex;
  align-items: center;

  grid-gap: 10px;

  gap: 10px;
}
.styles_time__BPhV2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
}
.styles_owner__2UH3x {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
}
.styles_ethContainer__Dq407 {
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 5px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 7px;
  gap: 7px;
}
.styles_eth__1MKs1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;

  color: #fafafa;
}
.styles_ethIcon__2BouR {
  width: 11.4px;
  height: 19.2px;
}
.styles_likeCommentSend__2zA7_ {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 10px 20px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
}
.styles_like__2_7Lu,
.styles_comment__1-Oi8 {
  display: flex;
  align-items: center;
  grid-gap: 3px;
  gap: 3px;
}
.styles_icon__3DuBs {
  width: 20px;
  height: 17.8px;
  cursor: pointer;
}

.styles_number__W_oxY {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.72);
}
.styles_commentsWrapper__1lU_u {
  padding: 10px 20px;
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;
}
.styles_postInputContainer__1WZ7_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.styles_postInput__3w58m {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;

  color: var(--primary-color);
  background: none;
  border: none;
  outline: none;
  padding: 10px;
  padding-right: 18px;
  width: 100%;
}
.styles_postButton__6FADv {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #fafafa;
  background: #2f67fa;
  border-radius: 14px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media only screen and (max-width: 1280px) {
  .styles_wrapper__1AFyX {
    grid-template-columns: 1.2fr 1fr;
  }
}
@media only screen and (max-width: 1199px) {
  .styles_wrapper__1AFyX {
    grid-template-columns: 1fr;

    max-width: 500px;
    max-height: 2000px;

    top: 100px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .styles_postImage__ZpCnW {
    border-radius: 20px 20px 0 0;
  }
}
@media only screen and (max-width: 400px) {
  .styles_text__1_qH8 {
    font-size: 14px;
  }
  .styles_informationContainer__2jcZA {
    padding: 18px 10px;
  }
  .styles_commentsWrapper__1lU_u {
    padding: 10px 10px;
  }
  .styles_icon__3DuBs {
    width: 18px;
    height: 15.4px;
    cursor: pointer;
  }
  .styles_number__W_oxY {
    font-size: 12px;
  }
  .styles_postButton__6FADv {
    font-size: 13px;
  }
}

.styles_overlay__v2Elj {
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.6;
  z-index: 90;
  width: 100%;
  height: 100%;
}
.styles_smallModal__3VPak {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  background-color: var(--primary-bg);
  padding: 32px 24px 24px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 91;
  text-align: center;
}
.styles_smallModal__3VPak p {
  line-height: 140%;
  color: #fafafa;
}

.styles_btnDiv__3zpSR {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.styles_invertBtn__-t4IL {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid white;
  padding: 10px 45px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.styles_invertBtn__-t4IL:hover {
  background-color: white;
  color: black;
}
.styles_button__nMmG8 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 10px 45px;
  cursor: pointer;
}
.styles_buttonLoader__3wvVj {
  display: flex;
  justify-content: center;
}

.CheckBox_container__2GXz9 {
  display: flex;
  align-items: center;

  color: #ffffff;
}

.CheckBox_container__2GXz9 input[type="checkbox"] {
  display: none;
}

.CheckBox_checkmark__EFkXU {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #42ccd7;
  margin-right: 5px;
  position: relative;

  cursor: pointer;
}

.CheckBox_container__2GXz9 input[type="checkbox"]:checked + .CheckBox_checkmark__EFkXU:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;

  background: #42ccd7;
  border-radius: 50%;
}
.CheckBox_text__1URWi {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.styles_editInformationContainer__vIoWd {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 50;
  position: relative;
}
.styles_editInformation__2cfY2 {
  border-radius: 0px 0px 20px 0px;
  background: #181e3c;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.7);

  max-width: 1143px;

  margin: 0 auto;
}

.styles_titleContainer__2Y_s7 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #242c56;
  border-radius: 20px 20px 0px 0px;
  padding: 15px 14px;
  position: relative;
}

.styles_title__10R3l {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 125%;
  color: #fafafa;
}
.styles_wrapper__ehaCJ {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;

  min-height: 554px;
  max-height: 90vh;
  overflow-y: auto;
}
.styles_image__6NmAw {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 0px 0px 0px 20px;
}
.styles_details__Cc4nQ {
  display: flex;
  flex-direction: column;
}
.styles_userContainer__28Y85 {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  padding: 18px;
}
.styles_text__3LzJq {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;

  color: #fafafa;
}
.styles_editText__1gkI0 {
  font-weight: 400;
}
.styles_profile__3D3_u {
  width: 32px;
  height: 32px;
}
.styles_edits__1b4Lr,
.styles_tagPeople__1IrB3,
.styles_listForSaleContainer__2Pz4Y {
  display: flex;
  padding: 18px;
  grid-gap: 6px;
  gap: 6px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
  min-height: 130px;
}
.styles_edit__2FqSc {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.styles_tagPeople__1IrB3 {
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
  min-height: auto;
}
.styles_userIcon___mBDq {
  color: #fff;
  font-size: 22px;
}
.styles_listForSaleContainer__2Pz4Y {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.styles_listForSale__26cpU {
  display: flex;
  grid-gap: 6px;
  gap: 6px;
}
.styles_checkboxContainer__3Lk0R {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  padding-top: 6px;
  padding-left: 25px;
}
.styles_btnDiv__2tkFn {
  padding: 18px;
  display: grid;
  grid-template-columns: 147px 1fr;
  align-items: center;
  grid-gap: 20px 20px;
  gap: 20px 20px;
  margin-top: auto;
}
.styles_invertBtn__2oxFW {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid white;
  padding: 10px 45px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.styles_invertBtn__2oxFW:hover {
  background-color: white;
  color: black;
}
.styles_button__3VmX8 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 10px 45px;
  cursor: pointer;
}
.styles_close__12iTN {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  z-index: 91;
  color: #fff;
  font-size: 24px;
}
.styles_buttonLoader__MUaQ1 {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 991px) {
  .styles_wrapper__ehaCJ {
    grid-template-columns: 1fr;
    grid-gap: 25px;
    gap: 25px;
  }
  .styles_editInformation__2cfY2 {
    border-radius: 0 0 20px 20px;
  }
  .styles_image__6NmAw {
    border-radius: 0;
  }
}
@media only screen and (max-width: 520px) {
  .styles_btnDiv__2tkFn {
    padding: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px 10px;
    gap: 10px 10px;
  }
  .styles_image__6NmAw {
    border-radius: 0px;
  }
  .styles_userContainer__28Y85,
  .styles_edits__1b4Lr,
  .styles_tagPeople__1IrB3,
  .styles_buttonDiv__3H-HI.styles_listForSale__26cpU {
    padding: 18px 10px;
  }
}

.styles_wrapper__3wXYF {
  background: #0c1232;
  border-radius: 8px;
  width: 418px;
  padding: 10px 20px;
  padding-bottom: 10px;
}
.styles_nftMore__Mmkv- {
  position: absolute;
  top: 60px;
  right: 0;
  max-width: 320px;
  width: 100%;
  z-index: 1;
}
.styles_titleContainer__1T5GR {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  padding-top: 20px;
}
.styles_title__1STn4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #fafafa;
}
.styles_close__kyKIJ {
  color: #fafafa;
  font-size: 22px;
  cursor: pointer;
}

.styles_arrow__21zsS {
  font-size: 18px;
  color: #fafafa;
  cursor: pointer;
}
.styles_socials__2AYQu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 0.5px solid rgba(250, 250, 250, 0.72);
}

.styles_socials__2AYQu:last-child {
  border-bottom: none;
}
.styles_reportContaner__3aQem {
  border-top: 0.5px solid rgba(250, 250, 250, 0.72);
}
.styles_nameAndIcon__1V2lS {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.styles_name__3l6N2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;

  color: #fafafa;
}
.styles_socialIcon__3bmsV {
  width: 20px;
  height: 19.5px;
}

@media only screen and (max-width: 520px) {
  .styles_wrapper__3wXYF {
    width: 90%;
    padding: 25px 18px;
    padding-bottom: 18px;
  }
}

.styles_wrapper__1rXnX {
  background: #0c1232;
  border-radius: 8px;
  width: 418px;
  padding: 10px 20px;
  padding-bottom: 20px;
}
.styles_titleContainer__NHUQF {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  padding-top: 20px;
}
.styles_title__1LCp4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #fafafa;
}
.styles_close__2qO94 {
  color: #fafafa;
  font-size: 22px;
  cursor: pointer;
}
.styles_text__3VpPi {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: rgba(250, 250, 250, 0.72);
  padding: 15px 0;
}
.styles_messageBoxContainer__3LogU {
  padding: 18px 0;
  display: block;
}
.styles_messageBox__2dWnC {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: rgba(250, 250, 250, 0.72);
  max-width: 390px;
  width: 100%;
  margin: 0 auto;
  height: 75px;

  border: 1px solid #fafafa;
  border-radius: 10px;
  outline: none;
  background: none;
  padding: 15px;
  resize: none;
}
.styles_button__1ZyuC {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #fafafa;
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  width: 100%;
  border: none;
  outline: none;
  padding: 8px 15px;
  margin-top: 25px;
  cursor: pointer;
}

@media only screen and (max-width: 520px) {
  .styles_wrapper__1rXnX {
    width: 90%;
    padding: 25px 18px;
    padding-bottom: 18px;
  }
}

.styles_modalWrapper__2wp4- {
  display: grid;
  grid-template-columns: 228px 1fr;
  align-items: center;
  grid-gap: 25px;
  gap: 25px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(150px);
          backdrop-filter: blur(150px);
  border-radius: 10px;
  padding: 50px 30px;
  padding-right: 50px;
  position: relative;
  width: 100%;
  width: 772px;
}
.styles_close__DQXqy {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  color: var(--primary-color);
  cursor: pointer;
}
.styles_image__3kU5G {
  width: 100%;
  border-radius: 10px;
}
.styles_nameId__1IKrn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;

  letter-spacing: -0.02em;

  color: #ffffff;
}
.styles_name__3VkNc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 120%;
  color: #42ccd7;
}
.styles_keyAndValueContainer__3NaEs {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  padding: 10px 0;
}
.styles_keyAndValue__9B2Fy {
  display: grid;
  grid-template-columns: 130px 1fr;
  align-items: center;
}
.styles_key__3-R-K {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: rgba(250, 250, 250, 0.72);
}
.styles_value__2USzX {
  color: #42ccd7;
}
.styles_ethValue__69m55 {
  font-weight: 700;
  color: #fff;
}
.styles_button__CPy8P {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #fafafa;
  outline: none;

  border-radius: 10px;
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border: none;
  padding: 9px 25px;
  cursor: pointer;
  width: 100%;
}
.styles_buttonLoader__3q47f {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 991px) {
  .styles_modalWrapper__2wp4- {
    width: 90%;
  }
}
@media only screen and (max-width: 767px) {
  .styles_modal__26cju {
    top: 50px;
    width: auto;
  }
  .styles_modalWrapper__2wp4- {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .styles_image__3kU5G {
    width: 280px;
    height: 150px;
    object-fit: cover;
    object-position: center;
  }
}
@media only screen and (max-width: 380px) {
  .styles_modalWrapper__2wp4- {
    padding: 50px 15px;
    padding-bottom: 30px;
  }
}

.styles_overlay__lM_CA {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 88;
}

.styles_modalWrapp__2Uvx- {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90%;
  max-width: 480px;
  max-height: 630px;
  overflow-y: auto;
  z-index: 900;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 30px;
  border-radius: 8px;
  background-color: var(--primary-bg);
  height: 90%;
}

.styles_modalHeader__12zOk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}

.styles_modalHeader__12zOk h3 {
  color: white;
  font-size: 22px;
  cursor: pointer;
}

.styles_modalHeader__12zOk svg {
  width: 30px;
  object-fit: contain;
  cursor: pointer;
}

.styles_addGrid__1xque {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  align-items: flex-start;
}

.styles_addGrid__1xque > p {
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
}

.styles_addedPeople__tjGYi {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.styles_addedPeople__tjGYi input {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: white;
}

.styles_taggedPerson__16I94 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  grid-gap: 4px;
  gap: 4px;
  background-color: white;
  border-radius: 8px;
  color: var(--primary-bg);
}

.styles_taggedPerson__16I94 p {
  font-size: 16px;
}

.styles_taggedPerson__16I94 svg {
  width: 15px;
  object-fit: contain;
  cursor: pointer;
}

.styles_suggestionDiv__1_tbJ {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;
}

.styles_suggestionDiv__1_tbJ h3 {
  font-size: 20px;
  color: white;
  font-weight: 700;
}

.styles_suggestedPerson__26Qc0 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
}

.styles_leftSuggest__1wVuC {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.styles_leftSuggest__1wVuC img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border: 50%;
}

.styles_leftSuggest__1wVuC p {
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.styles_checkBox__3jU9F {
  border-radius: 50%;
  aspect-ratio: 1/1;
  min-width: 30px;
  border: 1.5px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.styles_checkBox__3jU9F svg {
  width: 17px;
  object-fit: contain;
  pointer-events: none;
}

.styles_button___6VIm {
  width: 100%;
}

@media only screen and (max-width: 400px) {
  .styles_modalWrapp__2Uvx- {
    width: 95%;
    padding: 20px 15px;
  }
}

.styles_post__1Ns3R {
  background: #ffffff0f;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 20px;
  width: 100%;
}
.styles_header__2RPap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;
}
.styles_userContainer__3z1tL {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.styles_userImg__1bdMe {
  width: 49px;
  height: 44.1px;
  display: block;
}
.styles_text__FjzkS {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: var(--primary-color);
}
.styles_userName__3gLvH {
  cursor: pointer;
}
.styles_subText__2JUrn {
  font-weight: 400;
}
.styles_more__2C4Yb {
  color: var(--primary-color);
  font-size: 24px;
  cursor: pointer;
}
.styles_imageContainer__1aQlD {
  width: 100%;
  /* max-height: 443px; */
  height: 100%;
  position: relative;
}
.styles_nftImage__3NyCS {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.styles_userIcon__lGdvo {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 20px;
  bottom: 10px;
  z-index: 1;
  cursor: pointer;
}
.styles_informationContainer__2koaU {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  padding: 15px 20px;
}
.styles_information__3GiVM {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}
.styles_likeCommentSend__3tS5D {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.styles_like__129SH,
.styles_comment__1rhpn {
  display: flex;
  align-items: center;
  grid-gap: 3px;
  gap: 3px;
}
.styles_icon__JUnwS {
  width: 20px;
  height: 17.8px;
  cursor: pointer;
}

.styles_number__Ctvtk {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.72);
}
.styles_idAndbutton__3CJT7 {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}
.styles_button__3Luw2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #42ccd7;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
}
.styles_aboutPost__2s5j_ {
  display: flex;
  align-items: center;

  grid-gap: 10px;

  gap: 10px;
}
.styles_time__30Eoe {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
}
.styles_owner__16GhG {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
}
.styles_ethContainer__1n0GL {
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 5px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 7px;
  gap: 7px;
  cursor: pointer;
}
.styles_eth__2gT3l {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;

  color: #fafafa;
}
.styles_ethIcon__1NM07 {
  width: 11.4px;
  height: 19.2px;
}

.styles_hexagon__3-5XT {
  width: 50px;
  height: 50px;
}

.styles_hexagon__3-5XT:first-child {
  -webkit-clip-path: polygon(
    92.32051% 40%,
    93.79385% 43.1596%,
    94.69616% 46.52704%,
    95% 50%,
    94.69616% 53.47296%,
    93.79385% 56.8404%,
    92.32051% 60%,
    79.82051% 81.65064%,
    77.82089% 84.50639%,
    75.35575% 86.97152%,
    72.5% 88.97114%,
    69.3404% 90.44449%,
    65.97296% 91.34679%,
    62.5% 91.65064%,
    37.5% 91.65064%,
    34.02704% 91.34679%,
    30.6596% 90.44449%,
    27.5% 88.97114%,
    24.64425% 86.97152%,
    22.17911% 84.50639%,
    20.17949% 81.65064%,
    7.67949% 60%,
    6.20615% 56.8404%,
    5.30384% 53.47296%,
    5% 50%,
    5.30384% 46.52704%,
    6.20615% 43.1596%,
    7.67949% 40%,
    20.17949% 18.34936%,
    22.17911% 15.49361%,
    24.64425% 13.02848%,
    27.5% 11.02886%,
    30.6596% 9.55551%,
    34.02704% 8.65321%,
    37.5% 8.34936%,
    62.5% 8.34936%,
    65.97296% 8.65321%,
    69.3404% 9.55551%,
    72.5% 11.02886%,
    75.35575% 13.02848%,
    77.82089% 15.49361%,
    79.82051% 18.34936%
  );
  clip-path: polygon(
    92.32051% 40%,
    93.79385% 43.1596%,
    94.69616% 46.52704%,
    95% 50%,
    94.69616% 53.47296%,
    93.79385% 56.8404%,
    92.32051% 60%,
    79.82051% 81.65064%,
    77.82089% 84.50639%,
    75.35575% 86.97152%,
    72.5% 88.97114%,
    69.3404% 90.44449%,
    65.97296% 91.34679%,
    62.5% 91.65064%,
    37.5% 91.65064%,
    34.02704% 91.34679%,
    30.6596% 90.44449%,
    27.5% 88.97114%,
    24.64425% 86.97152%,
    22.17911% 84.50639%,
    20.17949% 81.65064%,
    7.67949% 60%,
    6.20615% 56.8404%,
    5.30384% 53.47296%,
    5% 50%,
    5.30384% 46.52704%,
    6.20615% 43.1596%,
    7.67949% 40%,
    20.17949% 18.34936%,
    22.17911% 15.49361%,
    24.64425% 13.02848%,
    27.5% 11.02886%,
    30.6596% 9.55551%,
    34.02704% 8.65321%,
    37.5% 8.34936%,
    62.5% 8.34936%,
    65.97296% 8.65321%,
    69.3404% 9.55551%,
    72.5% 11.02886%,
    75.35575% 13.02848%,
    77.82089% 15.49361%,
    79.82051% 18.34936%
  );
  object-fit: cover;
  object-position: center;
  background-color: #616478;
}

@media only screen and (max-width: 400px) {
  .styles_text__FjzkS {
    font-size: 14px;
  }
  .styles_informationContainer__2koaU {
    padding: 18px 10px;
  }
  .styles_icon__JUnwS {
    width: 18px;
    height: 15.4px;
    cursor: pointer;
  }
  .styles_number__Ctvtk {
    font-size: 12px;
  }
}

.styles_postContainer__1CcjF {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  grid-gap: 50px;
  gap: 50px;
  padding-top: 40px;
}
.styles_postsWrapper__1dYkU {
  max-width: 602px;
  margin-right: 360px;
  width: 100%;
  padding: 0 35px;
}

.styles_posts__2KtwX {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.styles_postsWrapper__1dYkU::-webkit-scrollbar,
.styles_posts__2KtwX::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.styles_postsWrapper__1dYkU,
.styles_posts__2KtwX {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.styles_arrow__2IXFs {
  color: #fafafa;
  font-size: 32px;
  display: none;
  position: fixed;
  right: 0;
  top: 110px;
  cursor: pointer;
}
@media only screen and (max-width: 1199px) {
  .styles_postsWrapper__1dYkU {
    margin-right: 0px;
  }
  .styles_arrow__2IXFs {
    display: block;
  }
  .styles_postWrapper__8zlF4 {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 991px) {
  .styles_posts__2KtwX {
    max-height: calc(100vh - 205px);
  }
  .styles_arrow__2IXFs {
    top: 170px;
  }
}
@media only screen and (max-width: 520px) {
  .styles_postsWrapper__1dYkU {
    padding: 0 5px;
  }
}

.MarketPlace_marketPlace__3qfHT {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 101px);
}
.MarketPlace_heading__ukgRJ {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 115%;
  text-align: center;
  color: #ffff;
  padding-bottom: 10px;
}
@media only screen and (max-width: 991px) {
  .MarketPlace_marketPlace__3qfHT {
    min-height: calc(100vh - 165px);
  }
}
@media only screen and (max-width: 520px) {
  .MarketPlace_heading__ukgRJ {
    font-size: 32px;
  }
}

.AddMemberModal_overlay__1b9Fj {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 88;
}
.AddMemberModal_modalWrapp__2zjnL {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90%;
  max-width: 480px;
  max-height: 630px;
  overflow-y: auto;
  z-index: 90;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 90%;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 30px;
  border-radius: 8px;
  background-color: var(--primary-bg);
}
.AddMemberModal_modalHeader__v9Vjx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}
.AddMemberModal_modalHeader__v9Vjx h3 {
  color: white;
  font-size: 22px;
  cursor: pointer;
}
.AddMemberModal_modalHeader__v9Vjx svg {
  width: 30px;
  object-fit: contain;
  cursor: pointer;
}
.AddMemberModal_addGrid__qGP1A {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  align-items: flex-start;
}
.AddMemberModal_addGrid__qGP1A > p {
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
}
.AddMemberModal_addedPeople__2Uzig {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}
.AddMemberModal_addedPeople__2Uzig input {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: white;
}
.AddMemberModal_taggedPerson__2aIrx {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  grid-gap: 4px;
  gap: 4px;
  background-color: white;
  border-radius: 8px;
  color: var(--primary-bg);
}
.AddMemberModal_taggedPerson__2aIrx p {
  font-size: 16px;
}
.AddMemberModal_taggedPerson__2aIrx svg {
  width: 15px;
  object-fit: contain;
  cursor: pointer;
}
.AddMemberModal_suggestionDiv__6xAWK {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;
}
.AddMemberModal_suggestionDiv__6xAWK h3 {
  font-size: 20px;
  color: white;
  font-weight: 700;
}
.AddMemberModal_suggestedPerson__Kggjl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;
}
.AddMemberModal_leftSuggest__1px-W {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.AddMemberModal_leftSuggest__1px-W img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border: 50%;
}
.AddMemberModal_leftSuggest__1px-W p {
  color: white;
  font-size: 16px;
  font-weight: 500;
}
.AddMemberModal_checkBox__3GJx_ {
  border-radius: 50%;
  aspect-ratio: 1/1;
  min-width: 30px;
  border: 1.5px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* //checked box  */
}
.AddMemberModal_checkBox__3GJx_ svg {
  width: 17px;
  object-fit: contain;
  pointer-events: none;
}
.AddMemberModal_button__111WS {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 10px 45px;
  cursor: pointer;
  width: 100%;
}
@media only screen and (max-width: 400px) {
  .AddMemberModal_modalWrapp__2zjnL {
    width: 95%;
    padding: 20px 15px;
  }
}

.GroupPhoto_overlay__17NPL {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 88;
}
.GroupPhoto_modalWrapp__3W5Lc {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90%;
  max-width: 700px;
  max-height: 490px;
  overflow-y: auto;
  z-index: 90;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 30px;
  border-radius: 8px;
  height: 90%;
  background-color: var(--primary-bg);
}
.GroupPhoto_modalHeader__E3KfH {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}
.GroupPhoto_modalHeader__E3KfH h3 {
  color: white;
  font-size: 22px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}
.GroupPhoto_modalHeader__E3KfH svg {
  min-width: 30px;
  max-width: 30px;
  object-fit: contain;
  cursor: pointer;
}
.GroupPhoto_photosDiv__hSImf {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  grid-auto-rows: 1fr;
  width: 100%;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 10px;
  max-height: 320px;
  height: 100%;
  padding-right: 10px;
  overflow-y: auto;
}
.GroupPhoto_photosDiv__hSImf button {
  width: 100%;
  aspect-ratio: 1/1;
  background-color: transparent;
  cursor: pointer;
}
.GroupPhoto_photosDiv__hSImf button img {
  border-radius: 8px;
  border: 4px solid transparent;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
  height: 100%;
}
.GroupPhoto_button__QGlbd {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 10px 45px;
  cursor: pointer;
}

.Waveform_waveform__2Rl43 {
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 58px;
}
.Waveform_waveform__2Rl43 button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-top: 4px;
}
.Waveform_waveform__2Rl43 button svg {
  font-size: 30px;
  fill: white;
}
.Waveform_mainWave__3P03d {
  width: 180px;
}

.ChatRoom_chatRoom__1jQ12 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  height: 100%;
}
.ChatRoom_chatHeader__1lChv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;
  border-bottom: 1px solid #fafafa33;
  padding: 20px 20px;
}
.ChatRoom_chatHeader__1lChv img,
.ChatRoom_placeHolderImg__95CmX {
  width: 40px;
  object-fit: contain;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.ChatRoom_placeHolderImg__95CmX {
  background-color: #fafafa;
}
.ChatRoom_rightHeader__xCdLo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}
.ChatRoom_rightHeader__xCdLo h3 {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 400;
}
.ChatRoom_rightHeader__xCdLo p {
  color: #fafafab8;
  font-size: 14px;
  font-weight: 500;
}
.ChatRoom_chatMsgs__3PjyP,
.ChatRoom_settingDiv__3KVy7 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
  padding-inline: 40px;
  overflow-y: auto;
}

.ChatRoom_sentMsg__3MZGm {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  align-self: flex-end;
}
.ChatRoom_sentMsg__3MZGm .ChatRoom_chatMsgDiv__2GRJh {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  padding: 4px 8px;
  background-color: #ffffff1a;
  border-radius: 8px;
}
.ChatRoom_sentMsg__3MZGm .ChatRoom_chatImgDiv__3iUwC {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  padding: 4px 8px;
  background-color: transparent;
}
.ChatRoom_msgDate__1FuCg {
  display: flex;
  justify-content: center;
  width: 100%;
}
.ChatRoom_msgDate__1FuCg div {
  background-color: #ffffff1a;
  border-radius: 8px;
  padding: 4px 8px;
  color: var(--primary-color);
}
.ChatRoom_sentMsg__3MZGm div {
  color: var(--primary-color);
}
.ChatRoom_sentMsg__3MZGm span {
  color: #fafafab8;
  font-size: 12px;
  padding-left: 5px;
  align-self: flex-end;
}
.ChatRoom_recievedMsg__1JcuI {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  align-self: flex-start;
}
.ChatRoom_recievedMsg__1JcuI .ChatRoom_chatMsgDiv__2GRJh {
  display: flex;
  justify-content: flex-start;
  grid-gap: 8px;
  gap: 8px;
  padding: 4px 8px;
  background: var(--primary-bg);
  border: 1px solid rgba(250, 250, 250, 0.2);
  border-radius: 8px;
}
.ChatRoom_recievedMsg__1JcuI .ChatRoom_chatImgDiv__3iUwC {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  padding: 4px 8px;
}
.ChatRoom_recievedMsg__1JcuI div {
  color: var(--primary-color);
}
.ChatRoom_recievedMsg__1JcuI span {
  color: #fafafab8;
  font-size: 12px;
  padding-left: 5px;
  align-self: flex-end;
}
.ChatRoom_chatMsgDiv__2GRJh .ChatRoom_sender__2cvAP {
  font-weight: 700;
}
.ChatRoom_message__Iw3et {
  padding-left: 10px;
}
.ChatRoom_backRow__24Kti {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ChatRoom_backRow__24Kti svg {
  cursor: pointer;
  width: 25px;
  object-fit: contain;
}
.ChatRoom_backRow__24Kti button,
.ChatRoom_partcipantsHead__3LFvr button {
  color: #41c6da;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.ChatRoom_centerDetails__3X2xl {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  margin: 20px 0px;
}
.ChatRoom_centerDetails__3X2xl > p {
  font-size: 16px;
  color: white;
}
.ChatRoom_groupPhotoDiv__3PYtG {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
}
.ChatRoom_groupPhotoDiv__3PYtG button,
.ChatRoom_nameDisplayed__v7Kpd button,
.ChatRoom_descDisplayed__3AND6 button,
.ChatRoom_changeDesc__17BsZ button {
  background-color: transparent;
  color: #41c6da;
  font-size: 16px;
  cursor: pointer;
  border: 0;
  font-weight: 600;
}
.ChatRoom_groupPhoto__zl2IT {
  width: 100px;
  height: 90px;
  background-color: white;
  -webkit-clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
          clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}
.ChatRoom_desc__3mZke {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  width: 100%;
  background-color: transparent;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.ChatRoom_desc__3mZke svg {
  width: 25px;
  object-fit: contain;
}
.ChatRoom_participantsDiv__3mopN {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 0px;
  width: 100%;
  max-width: 450px;
  margin-inline: auto;
  border-block: 1px solid #fafafa26;
}
.ChatRoom_partcipantsHead__3LFvr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ChatRoom_partcipantsHead__3LFvr p {
  color: white;
  font-weight: 600;
  font-size: 16px;
}
.ChatRoom_partcipant__1kp5p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ChatRoom_partcipant__1kp5p > div {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.ChatRoom_partcipant__1kp5p > div img {
  width: 40px;
  object-fit: contain;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.ChatRoom_partcipant__1kp5p > div p {
  font-size: 16px;
  color: white;
}
.ChatRoom_partcipant__1kp5p button,
.ChatRoom_deleteBtn__3hf6m button {
  color: #fe595a;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.ChatRoom_deleteBtn__3hf6m {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  max-width: 450px;
  margin-inline: auto;
}
.ChatRoom_nameDisplayed__v7Kpd {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  text-align: center;
  color: white;
  max-width: 442px;
  width: 100%;
}
.ChatRoom_changeName__2sndD {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  max-width: 440px;
}
.ChatRoom_changeName__2sndD input,
.ChatRoom_nameDisplayed__v7Kpd input {
  width: 100%;
  border: 1px solid white;
  border-radius: 5px;
  background-color: transparent;
  color: white;
  padding: 10px;
}

.ChatRoom_descDisplayed__3AND6 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  text-align: center;
  color: white;
  max-width: 442px;
  width: 100%;
}
.ChatRoom_changeDesc__17BsZ {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  max-width: 440px;
}
.ChatRoom_changeDesc__17BsZ textarea,
.ChatRoom_descDisplayed__3AND6 textarea {
  width: 100%;
  height: 150px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: transparent;
  color: white;
  padding-top: 10px;
  padding-left: 10px;
}
.ChatRoom_overlay__1KRp8 {
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.6;
  z-index: 90;
  width: 100%;
  height: 100%;
}
.ChatRoom_smallModal__pkCuK {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  background-color: var(--primary-bg);
  padding: 32px 24px 24px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 91;
  text-align: center;
}
.ChatRoom_smallModal__pkCuK p {
  line-height: 140%;
  color: #fafafa;
}
.ChatRoom_btnDiv__3kC6b {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.ChatRoom_invertBtn__2CvkS {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid white;
  padding: 10px 45px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.ChatRoom_invertBtn__2CvkS:hover {
  background-color: white;
  color: black;
}
.ChatRoom_button__gs25J {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 10px 45px;
  cursor: pointer;
}
.ChatRoom_chatImg__2l-RW {
  height: 114px;
  object-fit: contain;
  border-radius: 8px;
}
.ChatRoom_chatMsgs__3PjyP .ChatRoom_loadingAudio__3H-OJ {
  display: flex;
  color: var(--primary-color);
  justify-content: center;
  height: 66px;
  width: 278px;
}

@media screen and (max-width: 1280px) {
  .ChatRoom_chatMsgs__3PjyP,
  .ChatRoom_settingDiv__3KVy7,
  .ChatRoom_chatHeader__1lChv {
    padding-inline: 20px;
  }
}

.ChatRoomSidebar_chatRoom__3Um0u {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  padding: 10px 5px;
  border-radius: 5px;
}
.ChatRoomSidebar_chatRoom__3Um0u img,
.ChatRoomSidebar_placeHolderImg__2uWD2 {
  width: 45px;
  object-fit: contain;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.ChatRoomSidebar_placeHolderImg__2uWD2 {
  background-color: #fafafa;
}
.ChatRoomSidebar_groupImages__31gkE {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  isolation: isolate;
}
.ChatRoomSidebar_groupImages__31gkE img:nth-child(2) {
  position: absolute;
  left: 25%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -1;
}
.ChatRoomSidebar_groupImages__31gkE img:nth-child(3) {
  position: absolute;
  left: 45%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -2;
}
.ChatRoomSidebar_chatRoom__3Um0u > img,
.ChatRoomSidebar_groupImages__31gkE img {
  min-width: 45px;
  max-width: 45px;
  border-radius: 50%;
  object-fit: contain;
}
.ChatRoomSidebar_rightRoom__bstOC {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  color: white;
  width: 100%;
}
.ChatRoomSidebar_roomDetails__2W63v {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
}
.ChatRoomSidebar_rightRoom__bstOC h4 {
  font-size: 16px;
  font-weight: 400;
  color: #fafafa;
}
.ChatRoomSidebar_rightRoom__bstOC p {
  font-size: 14px;
}
.ChatRoomSidebar_rightRoom__bstOC p span {
  color: #41c6da;
}

.ChatRoomSidebar_unread__wRgKZ {
  color: var(--primary-bg);
  background-color: #41c6da;
  width: 23px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 500;
  display: grid;
  place-items: center;
}

.ChatRoomSidebar_lastMessage__29o6h {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 10px;
}

.NewMsgModal_overlay__1M09p {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 88;
}
.NewMsgModal_modalWrapp__2fC9m {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90%;
  max-width: 480px;
  max-height: 630px;
  overflow-y: auto;
  z-index: 90;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 30px;
  border-radius: 8px;
  background-color: var(--primary-bg);
  height: 90%;
}
.NewMsgModal_modalHeader__2fi9Q {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}
.NewMsgModal_modalHeader__2fi9Q h3 {
  color: white;
  font-size: 22px;
  cursor: pointer;
}
.NewMsgModal_modalHeader__2fi9Q svg {
  width: 30px;
  object-fit: contain;
  cursor: pointer;
}
.NewMsgModal_addGrid__1NDmU {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  align-items: flex-start;
}
.NewMsgModal_addGrid__1NDmU > p {
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
}
.NewMsgModal_addedPeople__iFyoT {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}
.NewMsgModal_addedPeople__iFyoT input {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: white;
}
.NewMsgModal_taggedPerson__2SAOO {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  grid-gap: 4px;
  gap: 4px;
  background-color: white;
  border-radius: 8px;
  color: var(--primary-bg);
}
.NewMsgModal_taggedPerson__2SAOO p {
  font-size: 16px;
}
.NewMsgModal_taggedPerson__2SAOO svg {
  width: 15px;
  object-fit: contain;
  cursor: pointer;
}
.NewMsgModal_suggestionDiv__3TvHv {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;
}
.NewMsgModal_suggestionDiv__3TvHv h3 {
  font-size: 20px;
  color: white;
  font-weight: 700;
}
.NewMsgModal_suggestedPerson__IzVs6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
}
.NewMsgModal_leftSuggest__2Cxy4 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;
}
.NewMsgModal_leftSuggest__2Cxy4 img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border: 50%;
}
.NewMsgModal_leftSuggest__2Cxy4 p {
  color: white;
  font-size: 16px;
  font-weight: 500;
}
.NewMsgModal_checkBox__2ardx {
  border-radius: 50%;
  aspect-ratio: 1/1;
  min-width: 30px;
  border: 1.5px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.NewMsgModal_checkBox__2ardx svg {
  width: 17px;
  object-fit: contain;
  pointer-events: none;
}
.NewMsgModal_button__2u2D- {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 10px 45px;
  cursor: pointer;
  width: 100%;
}

.Message_messageWrap__1Bkpu {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: transparent;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 101px;
  position: relative;
  z-index: 1;
}
.Message_messageCont__1plFE {
  /* max-height: calc(100vh - 151px); */
  display: grid;
  grid-template-columns: 400px 1.5fr;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  width: 100%;
  align-items: flex-start;
  max-width: 90%;
}

.Message_shadow1__33OgM {
  left: 0;
  bottom: 0;
}
.Message_shadow2__3kObG {
  top: 0;
  right: 0;
}
.Message_messageRooms__3WK48 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  background-color: var(--primary-bg);
  border-radius: 16px;
  position: static;
  z-index: 1;
  max-height: calc(100vh - 160px);
  left: -600px;
}
.Message_roomHeader__3DWr_ {
  border-bottom: 1px solid #fafafa33;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-block: 20px;
  padding-inline: 20px;
}
.Message_roomHeader__3DWr_ h2 {
  color: #fafafa;
  font-weight: 700;
  font-size: 32px;
}
.Message_newChatBtn__2ihha {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 140%; */
  height: 40px;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 12px 30px;
  cursor: pointer;
}
.Message_paddingDiv__3-nEh {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
  padding-inline: 20px;
  padding-block: 20px;
  width: 100%;
  overflow-y: hidden;
}
.Message_scrollDiv__3oYDw {
  overflow-y: auto;
  flex-grow: 1;
  max-height: 100%;
  padding-right: 10px;
  width: 100%;
}
.Message_roomSearch__2SEAu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(250, 250, 250, 0.2);
  border-radius: 10px;
  height: 45px;
  grid-gap: 10px;
  gap: 10px;
  padding-inline: 10px;
}
.Message_roomSearch__2SEAu input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-family: "Roboto";
}
.Message_roomSearch__2SEAu svg {
  min-width: 20px;
  min-height: 20px;
  object-fit: contain;
  fill: #fafafab8;
}
/* //chat room css ------------------ */
.Message_roomChat__29QQS {
  position: relative;
  z-index: 2;
  width: 100%;
  background-color: var(--primary-bg);
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  border-radius: 16px;
  padding-bottom: 30px;
  max-height: calc(100vh - 160px);
}
.Message_chatPart__1S_sU {
  width: 100%;
}
.Message_inputDiv__2c_c8 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding-inline: 40px;
}
.Message_inputDiv__2c_c8 button {
  display: none;
}
.Message_inputDiv__2c_c8 > input {
  border: 1.5px solid #fafafa80;
  width: 100%;
  border-radius: 10px;
  height: 40px;
  color: #fafafa80;
  font-size: 16px;
  font-weight: 400;
  padding-left: 15px;
  background-color: transparent;
}
.Message_rightInput__2FWar {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.Message_rightInput__2FWar img {
  cursor: pointer;
}
.Message_mobToggle__25XP_,
.Message_overlay__OtLMe {
  display: none;
}

@media screen and (max-width: 1200px) {
  .Message_roomHeader__3DWr_ h2 {
    font-size: 20px;
  }

  .Message_roomHeader__3DWr_,
  .Message_inputDiv__2c_c8,
  .Message_paddingDiv__3-nEh {
    padding-inline: 20px;
  }
  .Message_mobToggle__25XP_ {
    display: inline-block;
    width: -webkit-max-content;
    width: max-content;
  }
  .Message_mobToggle__25XP_ svg {
    color: white;
    font-size: 25px;
    cursor: pointer;
  }
  .Message_messageWrap__1Bkpu {
    padding-inline: 20px;
    margin-top: 101px;
  }
  .Message_messageCont__1plFE {
    max-width: 100%;
    grid-template-rows: auto 1fr;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-template-columns: 1fr;
    max-height: none;
    height: auto;
  }
  .Message_messageRooms__3WK48 {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 91;
    max-height: 100%;
    max-width: 350px;
    transition: all 0.7s ease-in-out;
  }
  .Message_overlay__OtLMe {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 90;
    cursor: pointer;
    background-color: black;
    opacity: 0.7;
    display: block;
  }
  .Message_roomChat__29QQS {
    max-height: 100%;
    max-height: 650px;
  }
  .Message_sideBarLeft__UnnHl {
    left: 270px;
  }
}
@media screen and (max-width: 991px) {
  .Message_messageWrap__1Bkpu {
    margin-top: 165px;
  }
  .Message_roomChat__29QQS {
    max-height: 600px;
  }
  .Message_sideBarLeft__UnnHl {
    left: 0px;
  }
}

.MutualFriendModal_overlay__1O0V7 {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 88;
  cursor: pointer;
}
.MutualFriendModal_modalWrapp__2pqep {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90%;
  max-width: 480px;
  max-height: 468px;
  overflow-y: auto;
  z-index: 90;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 30px;
  border-radius: 8px;
  background-color: var(--primary-bg);
  height: 90%;
}
.MutualFriendModal_modalHeader__2PgtN {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  padding: 15px 0;
}
.MutualFriendModal_modalHeader__2PgtN h3 {
  color: white;
  font-size: 22px;
  cursor: pointer;
}
.MutualFriendModal_modalHeader__2PgtN svg {
  width: 30px;
  object-fit: contain;
  cursor: pointer;
}

.MutualFriendModal_suggestionDiv__IvLxv {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;
}
.MutualFriendModal_suggestionDiv__IvLxv h3 {
  font-size: 20px;
  color: white;
  font-weight: 700;
}
.MutualFriendModal_suggestedPerson__1Fclm {
  display: flex;

  align-items: center;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
}
.MutualFriendModal_leftSuggest__3WEIr {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;
}
.MutualFriendModal_userImage__2Vwgg {
  width: 45px;
  height: 45px;
  object-fit: contain;
  border: 50%;
}
.MutualFriendModal_leftSuggest__3WEIr p {
  color: white;
  font-size: 16px;
  font-weight: 500;
}
@media only screen and (max-width: 520px) {
  .MutualFriendModal_modalWrapp__2pqep {
    padding: 20px 20px;
  }
}

.styles_banner__1L-uE {
  position: relative;
  margin-top: 101px;
}

.styles_image__ZqKV7 {
  width: 100%;
  min-height: 185px;
  max-height: 350px;
  object-fit: contain;
  object-position: center;
}
.styles_nftAndFriends__fGvvp {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 90%;
}
.styles_infoBox__2hUCZ {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400px;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #fafafa;
  background: rgba(250, 250, 250, 0.2);
  -webkit-backdrop-filter: blur(13.5px);
          backdrop-filter: blur(13.5px);
  border-radius: 200px;
  padding: 10px 22px;
  display: flex;
  align-items: center;
  grid-gap: 7px;
  gap: 7px;
}
.styles_cursor__LDq90 {
  cursor: pointer;
}
.styles_banner__1L-uE {
  position: relative;
  margin-top: 101px;
}
@media only screen and (max-width: 991px) {
  .styles_banner__1L-uE {
    margin-top: 165px;
  }
}

@media only screen and (max-width: 767px) {
  .styles_nftAndFriends__fGvvp {
    justify-content: center;
  }
}

.CancelListingModal_overlay__rJ0T1 {
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.6;
  z-index: 90;
  width: 100%;
  height: 100%;
}
.CancelListingModal_smallModal__15oyX {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  background-color: var(--primary-bg);
  padding: 32px 24px 24px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 91;
  text-align: center;
}
.CancelListingModal_smallModal__15oyX p {
  line-height: 140%;
  color: #fafafa;
}
.CancelListingModal_btnDiv__WWxEN {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}
.CancelListingModal_invertBtn__2mRHv {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid white;
  padding: 10px 45px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.CancelListingModal_invertBtn__2mRHv:hover {
  background-color: white;
  color: black;
}
.CancelListingModal_button__14IIV {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 10px 45px;
  cursor: pointer;
}
.CancelListingModal_buttonLoader__3V-JD {
  display: flex;
  justify-content: center;
}

.CheckBox_container__3rx_W {
  display: flex;
  align-items: center;

  color: #ffffff;
}

.CheckBox_container__3rx_W input[type="checkbox"] {
  display: none;
}

.CheckBox_checkmark__310WD {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #42ccd7;
  margin-right: 5px;
  position: relative;

  cursor: pointer;
}

.CheckBox_container__3rx_W input[type="checkbox"]:checked + .CheckBox_checkmark__310WD:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;

  background: #42ccd7;
  border-radius: 50%;
}
.CheckBox_text__1h1Ec {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.styles_editInformationContainer__2hyNO {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 50;
  position: relative;
}
.styles_editInformation__2C0d8 {
  border-radius: 0px 0px 20px 0px;
  background: #181e3c;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.7);

  max-width: 1143px;

  margin: 0 auto;
}

.styles_titleContainer__1O_JX {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #242c56;
  border-radius: 20px 20px 0px 0px;
  padding: 15px 14px;
  position: relative;
}

.styles_title__139wM {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 125%;
  color: #fafafa;
}
.styles_wrapper__1kiRY {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;

  min-height: 554px;
  max-height: 90vh;
  overflow-y: auto;
}
.styles_editText__3pENA {
  font-weight: 400;
}
.styles_textArea__359vG {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: transparent;
  resize: none;
}
.styles_image__2Hu2R {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 0px 0px 0px 20px;
}
.styles_details__10UKP {
  display: flex;
  flex-direction: column;
}
.styles_userContainer__2EhLZ {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  padding: 18px;
}
.styles_text__dBY7E {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;

  color: #fafafa;
}
.styles_editText__3pENA {
  font-weight: 400;
}
.styles_profile__3s6Jd {
  width: 32px;
  height: 32px;
}
.styles_edits__zFfD4,
.styles_tagPeople__3Ld7n,
.styles_listForSaleContainer__255-J {
  display: flex;
  padding: 18px;
  grid-gap: 6px;
  gap: 6px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
  min-height: 130px;
}
.styles_edit__2Vkd- {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.styles_tagPeople__3Ld7n {
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
  min-height: auto;
}
.styles_userIcon__PEQnS {
  color: #fff;
  font-size: 22px;
}
.styles_listForSaleContainer__255-J {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.styles_listForSale__34Ebo {
  display: flex;
  grid-gap: 6px;
  gap: 6px;
}
.styles_checkboxContainer__1Shzp {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  padding-top: 6px;
  padding-left: 25px;
}
.styles_btnDiv__3OOIb {
  padding: 18px;
  display: grid;
  grid-template-columns: 147px 1fr;
  align-items: center;
  grid-gap: 20px 20px;
  gap: 20px 20px;
  margin-top: auto;
}
.styles_invertBtn__OyzoX {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid white;
  padding: 10px 45px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.styles_invertBtn__OyzoX:hover {
  background-color: white;
  color: black;
}
.styles_button__MzJeM {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 10px 45px;
  cursor: pointer;
}
.styles_close__1Zmhn {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  z-index: 91;
  color: #fff;
  font-size: 24px;
}
.styles_buttonLoader__mwc9n {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 991px) {
  .styles_wrapper__1kiRY {
    grid-template-columns: 1fr;
    grid-gap: 25px;
    gap: 25px;
  }
  .styles_editInformation__2C0d8 {
    border-radius: 0 0 20px 20px;
  }
  .styles_image__2Hu2R {
    border-radius: 0;
  }
}
@media only screen and (max-width: 520px) {
  .styles_btnDiv__3OOIb {
    padding: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px 10px;
    gap: 10px 10px;
  }
  .styles_image__2Hu2R {
    border-radius: 0px;
  }
  .styles_userContainer__2EhLZ,
  .styles_edits__zFfD4,
  .styles_tagPeople__3Ld7n,
  .styles_buttonDiv__2M4vm.styles_listForSale__34Ebo {
    padding: 18px 10px;
  }
}

.SinglePost_image__3vRjP {
  width: 100%;
}
.SinglePost_post__3uEJi {
  background: rgba(250, 250, 250, 0.05);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 16px;
}
.SinglePost_detailsContainer__3Mxg1 {
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}
.SinglePost_header__18Z5D {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.SinglePost_id__3l6JP {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
}
.SinglePost_more__2a2EE {
  color: #fff;
  font-size: 22px;
  cursor: pointer;
}
.SinglePost_moreButtonContainer__jKEDU {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 24px;
  background: #0c1232;
  border-radius: 10px;
  border: 1px solid rgba(250, 250, 250, 0.2);
  min-width: 170px;
  padding: 15px;
}
.SinglePost_moreButton__2eXX6 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #fafafa;
  cursor: pointer;
}
.SinglePost_valueContainer__3Hmol {
  background: #224dbd;
  border-radius: 10px;
  padding: 6px 8px;
  display: flex;
  align-content: center;
  grid-gap: 8px;
  gap: 8px;
}
.SinglePost_value__VEHEt {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #fafafa;
}
.SinglePost_lastSale__1J2Vg {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: rgba(250, 250, 250, 0.72);
}

.styles_posts__1nQ5P {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
  grid-gap: 5px;
  gap: 5px;
}

.styles_image__wiuCv {
  width: 100%;
}
.styles_post__1SUBv {
  background: rgba(250, 250, 250, 0.05);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 16px;
  cursor: pointer;
}
.styles_posts__1nQ5P:not(:has(div)) {
  color: #fff;
  font-family: var(--secondary-font);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.styles_detailsContainer__3i10M {
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}
.styles_id__59Ab- {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
}
.styles_valueContainer__yh4Uj {
  background: #224dbd;
  border-radius: 10px;
  padding: 6px 8px;
  display: flex;
  align-content: center;
  grid-gap: 8px;
  gap: 8px;
}
.styles_value__28Qun {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #fafafa;
}
.styles_lastSale__olVQ- {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: rgba(250, 250, 250, 0.72);
}

.styles_commentContainer__2DFIe {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 0;
}

.styles_aboutPost__kG6JG {
  display: flex;
  align-items: center;

  grid-gap: 10px;

  gap: 10px;
}
.styles_userImage__aNT9E {
  width: 32px;
  height: 32px;
}

.styles_commentAndUserName__3BF7c {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}
.styles_text__Y8-w- {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: var(--primary-color);
}
.styles_comment__Ewi6K {
  font-weight: 400;
}

.styles_timeAndReply__1s4ZC {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.styles_time__1ID3J {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
}
.styles_replayButton__1nbWl {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}
.styles_like__-IW-M {
  display: flex;
  align-items: center;
  grid-gap: 2px;
  gap: 2px;
}
.styles_icon__x1JmC {
  width: 20px;
  height: 17.8px;
  cursor: pointer;
}
.styles_number__1qmok {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.72);
  margin-top: 1px;
}
@media only screen and (max-width: 400px) {
  .styles_aboutPost__kG6JG {
    grid-gap: 6px;
    gap: 6px;
  }
  .styles_commentAndUserName__3BF7c {
    grid-gap: 6px;
    gap: 6px;
  }
  .styles_text__Y8-w- {
    font-size: 14px;
  }

  .styles_icon__x1JmC {
    width: 18px;
    height: 15.4px;
    cursor: pointer;
  }
  .styles_number__1qmok {
    font-size: 12px;
  }
  .styles_time__1ID3J {
    font-size: 12px;
  }
  .styles_replayButton__1nbWl {
    font-size: 12px;
  }
}

.styles_overlay__1mvr9 {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 88;
  cursor: pointer;
}
.styles_close__2JnIB {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 90;
  color: #fff;
  font-size: 32px;
}
.styles_wrapper__1QJQh {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  background: #20284d;

  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  position: fixed;
  z-index: 90;
  top: 200px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);

  width: 100%;
  max-width: 991px;
  max-height: 675px;
}
.styles_postImage__1CUQS {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px 0 0 20px;
}

.styles_header__nOm9L {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.styles_userContainer__1Dqmr {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.styles_userImg__OHW7y {
  width: 49px;
  height: 49;
  display: block;
}
.styles_text__3PX7e {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: var(--primary-color);
}
.styles_subText__xvAQS {
  font-weight: 400;
}
.styles_more___FWb9 {
  color: var(--primary-color);
  font-size: 24px;
  cursor: pointer;
}
.styles_informationContainer__2UELi {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px 20px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
}
.styles_information__2zpzU {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.styles_idAndbutton__2I2re {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}
.styles_details__1gzCs {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #42ccd7;
  cursor: pointer;
}
.styles_aboutPost__HBKcB {
  display: flex;
  align-items: center;

  grid-gap: 10px;

  gap: 10px;
}
.styles_time__3bHD7 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
  padding-left: 10px;
}
.styles_owner__Qcr-j {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
}
.styles_ethContainer__2JYml {
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 5px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 7px;
  gap: 7px;
}
.styles_eth__SBgJ2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;

  color: #fafafa;
}
.styles_ethIcon__4Lmfl {
  width: 11.4px;
  height: 19.2px;
}
.styles_likeCommentSend__2vaUI {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 10px 20px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
}
.styles_like__2o9fS,
.styles_comment__2ERih {
  display: flex;
  align-items: center;
  grid-gap: 3px;
  gap: 3px;
}
.styles_icon__3uo4H {
  width: 20px;
  height: 17.8px;
  cursor: pointer;
}

.styles_number__2J7Rq {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.72);
}
.styles_commentsWrapper__1dcPn {
  padding: 10px 20px;
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;
}
.styles_postInputContainer__BGkAB {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.styles_postInput__2n1Lo {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;

  color: var(--primary-color);
  background: none;
  border: none;
  outline: none;
  padding: 10px;
  padding-right: 18px;
  width: 100%;
}
.styles_postButton__U1wVd {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #fafafa;
  background: #2f67fa;
  border-radius: 14px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.styles_buttonLoader__3ZIL7 {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 1280px) {
  .styles_wrapper__1QJQh {
    grid-template-columns: 1.2fr 1fr;
  }
}
@media only screen and (max-width: 1199px) {
  .styles_wrapper__1QJQh {
    grid-template-columns: 1fr;

    max-width: 500px;
    max-height: 2000px;

    top: 100px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .styles_postImage__1CUQS {
    border-radius: 20px 20px 0 0;
  }
}
@media only screen and (max-width: 400px) {
  .styles_text__3PX7e {
    font-size: 14px;
  }
  .styles_informationContainer__2UELi {
    padding: 18px 10px;
  }
  .styles_commentsWrapper__1dcPn {
    padding: 10px 10px;
  }
  .styles_icon__3uo4H {
    width: 18px;
    height: 15.4px;
    cursor: pointer;
  }
  .styles_number__2J7Rq {
    font-size: 12px;
  }
  .styles_postButton__U1wVd {
    font-size: 13px;
  }
}

.SinglePost_image__2rawN {
  width: 100%;
  cursor: pointer;
}

.styles_posts__3erKG {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
  grid-gap: 5px;
  gap: 5px;
}

.styles_posts__3erKG:not(:has(div)) {
  color: #fff;
  font-family: var(--secondary-font);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.styles_image__2bCkb {
  width: 100%;
}

.MyProfile_pageContainer__1KBCU {
  padding-bottom: 30px;
}
.MyProfile_profileHeader__L0whi {
  display: grid;
  grid-template-columns: 1fr 177px;
  align-items: start;
  grid-gap: 50px;
  gap: 50px;
  padding-top: 65px;
  z-index: 1;
  position: relative;
}
.MyProfile_profileHeader__L0whi::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 40%;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #343ab9;
  opacity: 0.8;
  -webkit-filter: blur(300.176px);
          filter: blur(300.176px);
  z-index: -1;
}
.MyProfile_userInfo__cIOQB {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 5px;
  gap: 5px;
}
.MyProfile_user__1xdQF {
  position: absolute;
  top: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 120px;
  height: 108px;
}
.MyProfile_name__2AfRz {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #fafafa;
  padding-top: 10px;
}
.MyProfile_addressContainer__2A6NX {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  background: rgba(250, 250, 250, 0.15);
  border-radius: 10px;
  padding: 4px 8px;
}
.MyProfile_text__37X1k {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
}
.MyProfile_downArrow__2S8Z7 {
  color: var(--primary-color);
  cursor: pointer;
}
.MyProfile_address__b-iRr {
  color: #fff;
}
.MyProfile_actionContainer__2uTnv {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  position: relative;
}

.MyProfile_button__2pnSf {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #fafafa;
  outline: none;
  border: 1px solid #fafafa;
  border-radius: 10px;
  background: transparent;
  padding: 10px 25px;
  cursor: pointer;
}
.MyProfile_activeButton__2Uj-0 {
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border: none;
}
.MyProfile_shareContainer__2ilQF {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
.MyProfile_infoContainer__22SW9 {
  grid-column: 1/-1;
  grid-gap: 10px;
  gap: 10px;
  background: #0c1232;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MyProfile_info__1Rtxm {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.MyProfile_infoText__DJeTc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: #fafafa;
}
.MyProfile_url__3n3lo {
  color: #41c5db;
}
.MyProfile_postTypesContainer__35AA1 {
  padding: 30px 0;
  display: flex;
  grid-gap: 25px;
  gap: 25px;
  justify-content: space-between;
  align-items: center;
}
.MyProfile_postTypes__23o5D {
  background: rgba(250, 250, 250, 0.05);
  border: 1px solid rgba(250, 250, 250, 0.5);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 5px;
  gap: 5px;
  width: 249px;
  padding: 1px;
}
.MyProfile_postType__ZDLxp {
  border-radius: 8px;
  padding: 6.5px 15px;
  width: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease;
}
.MyProfile_activePostType__2i2sR {
  background: rgba(255, 255, 255, 0.3);
  transition: 0.3s ease;
}
.MyProfile_postTypeText__3pSTD {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(250, 250, 250, 1);
}
.MyProfile_activePostType__2i2sR .MyProfile_postTypeText__3pSTD {
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  .MyProfile_profileHeader__L0whi {
    grid-template-columns: 1fr;
    padding: 30px 0;
  }
  .MyProfile_profileHeader__L0whi::before {
    display: none;
  }
  .MyProfile_user__1xdQF {
    position: inherit;

    -webkit-transform: translate(0px);

            transform: translate(0px);
  }
  .MyProfile_actionContainer__2uTnv {
    max-width: 400px;
  }
  .MyProfile_actionContainer__2uTnv::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background: #343ab9;
    opacity: 0.8;
    -webkit-filter: blur(300.176px);
            filter: blur(300.176px);
    z-index: -1;
  }
}
@media only screen and (max-width: 520px) {
  .MyProfile_postCategoryContainer__2diOa {
    flex-direction: column;
  }
}

.MutualCollection_overlay__3ehpt {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 88;
  cursor: pointer;
}
.MutualCollection_modalWrapp__172ro {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90%;
  max-width: 480px;
  max-height: 468px;
  overflow-y: auto;
  z-index: 90;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 30px;
  border-radius: 8px;
  background-color: var(--primary-bg);
  height: 90%;
}
.MutualCollection_modalHeader__iyPcM {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  padding: 15px 0;
}
.MutualCollection_modalHeader__iyPcM h3 {
  color: white;
  font-size: 22px;
  cursor: pointer;
}
.MutualCollection_modalHeader__iyPcM svg {
  width: 30px;
  object-fit: contain;
  cursor: pointer;
}

.MutualCollection_suggestionDiv__3RgQ_ {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;
}
.MutualCollection_suggestionDiv__3RgQ_ h3 {
  font-size: 20px;
  color: white;
  font-weight: 700;
}
.MutualCollection_suggestedPerson__vaxL8 {
  display: flex;

  align-items: center;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
}
.MutualCollection_leftSuggest__37ceT {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.MutualCollection_groupImages__2eiuo {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  isolation: isolate;
  margin-right: 20px;
}
.MutualCollection_groupImages__2eiuo .MutualCollection_groupImage__ZhoBn:nth-child(2) {
  position: absolute;
  left: 25%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -1;
}
.MutualCollection_groupImages__2eiuo .MutualCollection_groupImage__ZhoBn:nth-child(3) {
  position: absolute;
  left: 45%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -2;
}

.MutualCollection_groupImage__ZhoBn {
  min-width: 32px;
  max-width: 32px;

  border-radius: 50%;
  object-fit: contain;
}
.MutualCollection_userImage__de0Zb {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 50%;
  border-radius: 5px;
  display: block;
}
.MutualCollection_leftSuggest__37ceT p {
  color: white;
  font-size: 16px;
  font-weight: 500;
}
@media only screen and (max-width: 520px) {
  .MutualCollection_modalWrapp__172ro {
    padding: 20px 20px;
  }
}

.styles_modal__2tmbX {
  position: fixed;
  top: 200px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 10;
  width: 772px;
}
.styles_overlay___BFdC {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(12, 18, 50, 0.6);
  z-index: 9;
  cursor: pointer;
}

.styles_modalWrapper__Gej7c {
  display: grid;
  grid-template-columns: 228px 1fr;
  align-items: center;
  grid-gap: 25px;
  gap: 25px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(150px);
          backdrop-filter: blur(150px);
  border-radius: 10px;
  padding: 50px 30px;
  padding-right: 50px;
  position: relative;
  width: 100%;
}
.styles_close__rmdvs {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  color: var(--primary-color);
  cursor: pointer;
}
.styles_image__1xbFs {
  width: 100%;
  border-radius: 10px;
}
.styles_nameId__3KvjY {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;

  letter-spacing: -0.02em;

  color: #ffffff;
}
.styles_name__HrPzl {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 120%;
  color: #42ccd7;
}
.styles_keyAndValueContainer__3Wl86 {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  padding: 10px 0;
}
.styles_keyAndValue__3lwQt {
  display: grid;
  grid-template-columns: 130px 1fr;
  align-items: center;
}
.styles_key__ph15w {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: rgba(250, 250, 250, 0.72);
}
.styles_value__3amsG {
  color: #42ccd7;
}
.styles_ethValue__3rngW {
  font-weight: 700;
  color: #fff;
}
.styles_button__1HSPg {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #fafafa;
  outline: none;

  border-radius: 10px;
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border: none;
  padding: 9px 25px;
  cursor: pointer;
  width: 100%;
}
.styles_buttonLoader__2k3hl {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 991px) {
  .styles_modal__2tmbX {
    width: 90%;
  }
}
@media only screen and (max-width: 767px) {
  .styles_modal__2tmbX {
    top: 50px;
    width: auto;
  }
  .styles_modalWrapper__Gej7c {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    padding-bottom: 20px;
  }
  .styles_close__rmdvs {
    top: 12px;
  }
  .styles_image__1xbFs {
    width: 280px;
    height: 150px;
    object-fit: cover;
    object-position: center;
  }
}

.SinglePost_image__RcBmh {
  width: 100%;
}
.SinglePost_post__1kJ8n {
  background: rgba(250, 250, 250, 0.05);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 16px;
}
.SinglePost_detailsContainer__v8U4- {
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}
.SinglePost_header__2w-e_ {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.SinglePost_id__9wHKG {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
}
.SinglePost_more__8ywPa {
  color: #fff;
  font-size: 22px;
  cursor: pointer;
}
.SinglePost_moreButtonContainer__2-ELO {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 24px;
  background: #0c1232;
  border-radius: 10px;
  border: 1px solid rgba(250, 250, 250, 0.2);
  min-width: 170px;
  padding: 15px;
}
.SinglePost_moreButton__3gz3y {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #fafafa;
  cursor: pointer;
}
.SinglePost_valueContainer__3-zsP {
  background: #224dbd;
  border-radius: 10px;
  padding: 6px 8px;
  display: flex;
  align-content: center;
  grid-gap: 8px;
  gap: 8px;
  cursor: pointer;
}
.SinglePost_value__2s-24 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #fafafa;
}
.SinglePost_lastSale__1mDgp {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: rgba(250, 250, 250, 0.72);
}

.ForSale_posts__DVQHP {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 350px));
  grid-gap: 5px;
  gap: 5px;
}

.ForSale_posts__DVQHP:not(:has(div)) {
  color: #fff;
  font-family: var(--secondary-font);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.styles_commentContainer__2zn0h {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 0;
}

.styles_aboutPost__3tRnY {
  display: flex;
  align-items: center;

  grid-gap: 10px;

  gap: 10px;
}
.styles_userImage__dx5BO {
  width: 32px;
  height: 32px;
}

.styles_commentAndUserName__2NX6Q {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}
.styles_text__76dij {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: var(--primary-color);
}
.styles_comment__qVglT {
  font-weight: 400;
}

.styles_timeAndReply__2kwk9 {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.styles_time__hVf3p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
}
.styles_replayButton__xWOa1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}
.styles_like__1sSft {
  display: flex;
  align-items: center;
  grid-gap: 2px;
  gap: 2px;
}
.styles_icon__2QZCh {
  width: 20px;
  height: 17.8px;
  cursor: pointer;
}
.styles_number__1rm8p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.72);
  margin-top: 1px;
}
@media only screen and (max-width: 400px) {
  .styles_aboutPost__3tRnY {
    grid-gap: 6px;
    gap: 6px;
  }
  .styles_commentAndUserName__2NX6Q {
    grid-gap: 6px;
    gap: 6px;
  }
  .styles_text__76dij {
    font-size: 14px;
  }

  .styles_icon__2QZCh {
    width: 18px;
    height: 15.4px;
    cursor: pointer;
  }
  .styles_number__1rm8p {
    font-size: 12px;
  }
  .styles_time__hVf3p {
    font-size: 12px;
  }
  .styles_replayButton__xWOa1 {
    font-size: 12px;
  }
}

.styles_overlay__2V4VX {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 88;
  cursor: pointer;
}
.styles_close__3aqef {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 90;
  color: #fff;
  font-size: 32px;
}
.styles_wrapper__c-iBO {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  background: #20284d;

  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  position: fixed;
  z-index: 90;
  top: 200px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);

  width: 100%;
  max-width: 991px;
  max-height: 675px;
}
.styles_postImage__2tCWs {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px 0 0 20px;
}

.styles_header__3v-4R {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.styles_userContainer__3mwe9 {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.styles_userImg__y8RER {
  width: 49px;
  height: 49;
  display: block;
}
.styles_text__v40jF {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: var(--primary-color);
}
.styles_subText__cMQwk {
  font-weight: 400;
}
.styles_more__jU3VG {
  color: var(--primary-color);
  font-size: 24px;
  cursor: pointer;
}
.styles_informationContainer__1_FVc {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px 20px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
}
.styles_information__2kSXh {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.styles_idAndbutton__35vQJ {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}
.styles_details__2SpcI {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #42ccd7;
  cursor: pointer;
}
.styles_aboutPost__5JNfh {
  display: flex;
  align-items: center;

  grid-gap: 10px;

  gap: 10px;
}
.styles_time__NEVNv {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
  padding-left: 10px;
}
.styles_owner__1tL_1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
}
.styles_ethContainer__8pj-X {
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 5px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 7px;
  gap: 7px;
}
.styles_eth__3xjRV {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;

  color: #fafafa;
}
.styles_ethIcon__3xeXx {
  width: 11.4px;
  height: 19.2px;
}
.styles_likeCommentSend__1JJ0g {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 10px 20px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
}
.styles_like__iO0Ea,
.styles_comment__3HEg3 {
  display: flex;
  align-items: center;
  grid-gap: 3px;
  gap: 3px;
}
.styles_icon__162G5 {
  width: 20px;
  height: 17.8px;
  cursor: pointer;
}

.styles_number__376g9 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.72);
}
.styles_commentsWrapper__3CeD0 {
  padding: 10px 20px;
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;
}
.styles_postInputContainer__1TfFX {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.styles_postInput__y4ycZ {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;

  color: var(--primary-color);
  background: none;
  border: none;
  outline: none;
  padding: 10px;
  padding-right: 18px;
  width: 100%;
}
.styles_postButton__2Cg-F {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #fafafa;
  background: #2f67fa;
  border-radius: 14px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.styles_buttonLoader__21JDJ {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 1280px) {
  .styles_wrapper__c-iBO {
    grid-template-columns: 1.2fr 1fr;
  }
}
@media only screen and (max-width: 1199px) {
  .styles_wrapper__c-iBO {
    grid-template-columns: 1fr;

    max-width: 500px;
    max-height: 2000px;

    top: 100px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .styles_postImage__2tCWs {
    border-radius: 20px 20px 0 0;
  }
}
@media only screen and (max-width: 400px) {
  .styles_text__v40jF {
    font-size: 14px;
  }
  .styles_informationContainer__1_FVc {
    padding: 18px 10px;
  }
  .styles_commentsWrapper__3CeD0 {
    padding: 10px 10px;
  }
  .styles_icon__162G5 {
    width: 18px;
    height: 15.4px;
    cursor: pointer;
  }
  .styles_number__376g9 {
    font-size: 12px;
  }
  .styles_postButton__2Cg-F {
    font-size: 13px;
  }
}

.SinglePost_image__US8Qy {
  width: 100%;
  cursor: pointer;
}

.NftPosts_posts__3tMAL {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 350px));
  grid-gap: 5px;
  gap: 5px;
}

.NftPosts_posts__3tMAL:not(:has(div)) {
  color: #fff;
  font-family: var(--secondary-font);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.UserProfile_pageContainer__1GWzS {
  padding-bottom: 30px;
}
.UserProfile_profileHeader__1aGlT {
  display: grid;
  grid-template-columns: 1fr 400px;
  align-items: start;
  grid-gap: 50px;
  gap: 50px;
  padding-top: 65px;
  z-index: 2;
  position: relative;
}
.UserProfile_profileHeader__1aGlT::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 40%;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #343ab9;
  opacity: 0.8;
  -webkit-filter: blur(300.176px);
          filter: blur(300.176px);
  z-index: -1;
}
.UserProfile_userInfo__2mDye {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.UserProfile_user__2Ym8e {
  position: absolute;
  top: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 120px;
  height: 120px;
  cursor: pointer;
}
.UserProfile_profileImage__3_69x {
  position: absolute;
  z-index: 3;
  width: 355px;
  height: 355px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.UserProfile_overlay__2ejj7 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}
.UserProfile_name__3HuRs {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #fafafa;
  padding-top: 10px;
}
.UserProfile_addressContainer__3ZR-O {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  /* background: rgba(250, 250, 250, 0.15); */
  border-radius: 10px;
  padding: 4px 0px;
}
.UserProfile_text__2asTz {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(250, 250, 250, 1);
}
.UserProfile_address__1CLyD {
  color: #fff;
}
.UserProfile_copyIcon__ulcjm {
  cursor: pointer;
}
.UserProfile_actionContainer__1S9Oi {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  gap: 10px;
  position: relative;
}

.UserProfile_button__3_GUm {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #fafafa;
  outline: none;
  border: 1px solid #fafafa;
  border-radius: 10px;
  background: transparent;
  padding: 9px 20px;
  cursor: pointer;
}
.UserProfile_buttonLoader__cexaQ {
  display: flex;
  justify-content: center;
}
.UserProfile_activeButton__26d14 {
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border: none;
}
.UserProfile_infoContainer__18VIR {
  grid-column: 1/-1;
  grid-gap: 10px;
  gap: 10px;
  background: #0c1232;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.UserProfile_info__33Fkf {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  cursor: pointer;
}
.UserProfile_mutualImages__2cTFL {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  isolation: isolate;
  margin-right: 20px;
}
.UserProfile_collectionImgages__35jPO {
  max-width: 25px;
}
.UserProfile_mutualImages__2cTFL .UserProfile_mutualImage__1Dd0O:nth-child(2) {
  position: absolute;
  left: 25%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -1;
}
.UserProfile_mutualImages__2cTFL .UserProfile_mutualImage__1Dd0O:nth-child(3) {
  position: absolute;
  left: 45%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -2;
}

.UserProfile_mutualImage__1Dd0O {
  min-width: 32px;
  max-width: 32px;

  object-fit: contain;
}
.UserProfile_mutualCollection__178LZ {
  border-radius: 5px;
}

.UserProfile_infoText__3fUxX {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: #fafafa;
  white-space: nowrap;
  padding: 5px;
}
.UserProfile_url__3yw_S {
  color: #41c5db;
}
.UserProfile_postTypesContainer__X8kEr {
  padding: 30px 0;
  display: flex;
  grid-gap: 25px;
  gap: 25px;
  justify-content: space-between;
  align-items: center;
}
.UserProfile_postTypes__2cFz1 {
  background: rgba(250, 250, 250, 0.05);
  border: 1px solid rgba(250, 250, 250, 0.5);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 5px;
  gap: 5px;
  width: 249px;
  padding: 1px;
}
.UserProfile_postType__1pSGG {
  border-radius: 8px;
  padding: 6.5px 15px;
  width: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease;
}
.UserProfile_activePostType__1uc2O {
  background: rgba(255, 255, 255, 0.3);
  transition: 0.3s ease;
}
.UserProfile_postTypeText__1MRAi {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(250, 250, 250, 1);
}
.UserProfile_activePostType__1uc2O .UserProfile_postTypeText__1MRAi {
  font-weight: 700;
}
@media only screen and (max-width: 991px) {
  .UserProfile_profileHeader__1aGlT {
    grid-template-columns: 1fr;
    padding: 30px 0;
  }
  .UserProfile_profileImage__3_69x {
    width: 250px;
    height: 250px;
  }

  .UserProfile_profileHeader__1aGlT::before {
    display: none;
  }
  .UserProfile_user__2Ym8e {
    position: inherit;
    width: 100px;
    height: 100px;

    -webkit-transform: translate(0px);

            transform: translate(0px);
  }
  .UserProfile_actionContainer__1S9Oi {
    max-width: 400px;
  }
  .UserProfile_actionContainer__1S9Oi::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background: #343ab9;
    opacity: 0.8;
    -webkit-filter: blur(300.176px);
            filter: blur(300.176px);
    z-index: -1;
  }
}
@media only screen and (max-width: 520px) {
  .UserProfile_postCategoryContainer__3jCMb {
    flex-direction: column;
  }

  .UserProfile_profileImage__3_69x {
    width: 200px;
    height: 200px;
  }
}

.styles_modalWrapper__1feb3 {
  display: grid;
  grid-template-columns: 228px 1fr;
  align-items: center;
  grid-gap: 25px;
  gap: 25px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(150px);
          backdrop-filter: blur(150px);
  border-radius: 10px;
  padding: 50px 30px;
  padding-right: 50px;
  position: relative;
  width: 100%;
  width: 772px;
}
.styles_close__huRNb {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  color: var(--primary-color);
  cursor: pointer;
}
.styles_image__2uJd1 {
  width: 100%;
  border-radius: 10px;
}
.styles_nameId__3A_gs {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;

  letter-spacing: -0.02em;

  color: #ffffff;
}
.styles_name__1YWdS {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 120%;
  color: #42ccd7;
}
.styles_keyAndValueContainer__1PmH9 {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  padding: 10px 0;
}
.styles_keyAndValue__2K1b6 {
  display: grid;
  grid-template-columns: 130px 1fr;
  align-items: center;
}
.styles_key__3izm8 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: rgba(250, 250, 250, 0.72);
}
.styles_value__1B0iS {
  color: #42ccd7;
}
.styles_ethValue___qNjm {
  font-weight: 700;
  color: #fff;
}
.styles_button__1UzGm {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #fafafa;
  outline: none;

  border-radius: 10px;
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border: none;
  padding: 9px 25px;
  cursor: pointer;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .styles_modalWrapper__1feb3 {
    width: 90%;
  }
}
@media only screen and (max-width: 767px) {
  .styles_modal__SwT0i {
    top: 50px;
    width: auto;
  }
  .styles_modalWrapper__1feb3 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .styles_image__2uJd1 {
    width: 280px;
    height: 150px;
    object-fit: cover;
    object-position: center;
  }
}
@media only screen and (max-width: 380px) {
  .styles_modalWrapper__1feb3 {
    padding: 50px 15px;
    padding-bottom: 30px;
  }
}

.styles_wrapper__9SRBW {
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(9px);
          backdrop-filter: blur(9px);
  border-radius: 20px;
}
.styles_header__3mQA3 {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 18px;
}
.styles_heading__1otnc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 125%;
  color: #ffffff;
}
.styles_arrow__1bF4N {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
.styles_infoContainer__3Ni9i {
  padding: 18px;
  display: flex;
  flex-direction: column;
  grid-gap: 18px;
  gap: 18px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
}

.styles_info__2qeXN,
.styles_titleContainer__xT1nR {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  gap: 5px;
}
.styles_propertyContainer__3wzYY {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(154px, 1fr));
  grid-gap: 12px;
  gap: 12px;
  padding: 18px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
}
.styles_propertyBox__1uiQy {
  background: rgba(66, 204, 215, 0.11);
  border: 1px solid #42ccd7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  padding: 10px 8px;
}
.styles_text__Mre-9 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;

  color: #fafafa;
}
.styles_title__1OVdR {
  color: rgba(250, 250, 250, 0.72);
}
.styles_address__3HuYQ {
  color: #42ccd7;
}
.styles_token_id__1TSeG {
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 520px) {
  .styles_info__2qeXN,
  .styles_titleContainer__xT1nR {
    grid-template-columns: 1fr 0.8fr 1fr;
  }
  .styles_text__Mre-9 {
    font-size: 13px;
  }
  .styles_header__3mQA3,
  .styles_infoContainer__3Ni9i {
    padding: 18px 15px;
  }
}
@media only screen and (max-width: 400px) {
  .styles_info__2qeXN,
  .styles_titleContainer__xT1nR {
    grid-template-columns: 1fr 0.7fr 1fr;
  }
  .styles_text__Mre-9 {
    font-size: 12px;
  }
  .styles_header__3mQA3,
  .styles_infoContainer__3Ni9i {
    padding: 18px 12px;
  }
}

.styles_wrapper__2jewf {
  width: 100%;
  position: relative;
  z-index: 2;
  padding-top: 40px;
}
.styles_postDetails__2AoEG {
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-gap: 25px;

  gap: 25px;
}
.styles_loading__2_L1w {
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
  display: flex;

  grid-gap: 25px;

  gap: 25px;
}
.styles_header__2eNN1 {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  position: relative;
}
.styles_backArrow__3g4tU {
  color: #fff;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: -60px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
}
.styles_title__3a9BU {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.styles_price__2sAQY {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #fafafa;
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  grid-gap: 3px;
  gap: 3px;
  cursor: pointer;
}
.styles_ethIcon__2Hz26 {
  width: 11.4px;
  height: 19.2px;
}
.styles_name__2CuCW {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 130%;
  color: #42ccd7;
  padding-bottom: 30px;
}
.styles_imageContainer__1pQu9 {
  border-radius: 40.5128px;
  max-width: 553px;
}
.styles_image__2v9gR {
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 40.5128px;
}
.styles_detailsContainer__3mF-h {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  max-width: 557px;
  width: 99%;
}
.styles_priceContainer__2Wjmb {
  display: flex;
  align-items: flex-start;
  grid-gap: 36px;
  gap: 36px;
  padding-bottom: 10px;
}
.styles_keyAndValue__2mVJz {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 18px;
  gap: 18px;
}

.styles_key__3YbP6 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: rgba(250, 250, 250, 0.72);
}
.styles_valueContainer__2pjxF {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}
.styles_value__nCEub {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 120%;
  color: #fafafa;
}

@media only screen and (max-width: 1280px) {
  .styles_postDetails__2AoEG {
    max-width: calc(100% - 150px);
  }
}
@media only screen and (max-width: 1199px) {
  .styles_postDetails__2AoEG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 0 auto;
    max-width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .styles_priceContainer__2Wjmb {
    padding-left: 15px;
    grid-gap: 25px;
    gap: 25px;
  }
  .styles_keyAndValue__2mVJz {
    grid-gap: 10px;
    gap: 10px;
  }
  .styles_value__nCEub {
    font-size: 20px;
  }
}
@media only screen and (max-width: 520px) {
  .styles_backArrow__3g4tU {
    color: #fff;
    font-size: 30px;
    position: absolute;
    top: -15px;
    left: 0;
    cursor: pointer;
  }

  .styles_title__3a9BU {
    font-size: 28px;
  }
  .styles_heading__2wPbl {
    font-size: 18px;
  }
  .styles_price__2sAQY {
    font-size: 13px;
  }
  .styles_name__2CuCW {
    font-size: 18px;
  }
}

.NotLoginWallet_heading__2eoPN {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #ffffff;
  padding-top: 65px;
  padding-bottom: 35px;
}
.NotLoginWallet_wrapper__2wc0o {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;

  padding: 0 10px;
  padding-top: 100px;
}
.NotLoginWallet_title__16R5c {
  color: #fafafa;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
.NotLoginWallet_text__2wCy1 {
  color: #fafafa;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 991px) {
  .NotLoginWallet_heading__2eoPN {
    padding-top: 30px;
  }
  .NotLoginWallet_wrapper__2wc0o {
    padding-top: 40px;
  }
}

.styles_posts__1IzqN {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(228px, 1fr));
  grid-gap: 5px;
  gap: 5px;
}

.styles_image__G51Vw {
  width: 100%;
}
.styles_post__1LrX8 {
  background: rgba(250, 250, 250, 0.05);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 16px;
  overflow: hidden;
}
.styles_detailsContainer__2ooVe {
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 5px;
  gap: 5px;
}
.styles_token_link__bMYzQ {
  width: 100%;
}
.styles_id__3OQJ8 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  padding-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.styles_valueContainer__2a-W8 {
  background: #224dbd;
  border-radius: 10px;
  padding: 6px 8px;
  display: flex;
  align-content: center;
  grid-gap: 8px;
  gap: 8px;
}
.styles_value__3xvim {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #fafafa;
}
.styles_name__3lV6G {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: rgba(250, 250, 250, 0.72);
}
.styles_button__1GZgK {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #fafafa;
  outline: none;
  border: 1px solid #fafafa;
  border-radius: 10px;
  background: transparent;
  padding: 10px 25px;
  cursor: pointer;
  min-width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 25px;
}

.styles_pageContainer__1GIVo {
  padding-bottom: 30px;
}
.styles_title__2kV7R {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #ffffff;
  padding-top: 65px;
  padding-bottom: 35px;
}
.styles_profileHeader__1N3Tw {
  display: grid;
  grid-template-columns: 1fr 280px;
  align-items: start;
  grid-gap: 50px;
  gap: 50px;

  padding-bottom: 50px;
  z-index: 1;
  position: relative;
}

.styles_profileHeader__1N3Tw::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 40%;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #343ab9;
  opacity: 0.8;
  -webkit-filter: blur(300.176px);
          filter: blur(300.176px);
  z-index: -1;
}
.styles_userInfo__3UMf0 {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.styles_priceAndAddress__1qtRM {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.styles_user__3WgEG {
  width: 80px;
  height: 80px;
}
.styles_price__3Cu_p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  color: #fafafa;
}
.styles_addressContainer__UDPVv {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  background: rgba(250, 250, 250, 0.15);
  border-radius: 10px;
  padding: 4px 8px;
}
.styles_text__1Txcj {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
}
.styles_downArrow__22hMn {
  color: var(--primary-color);
  cursor: pointer;
}
.styles_address__2mvjY {
  color: #fff;
}
.styles_actionContainer__WE5sA {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  position: relative;
}

.styles_button__1LidR {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #fafafa;
  outline: none;
  border: 1px solid #fafafa;
  border-radius: 10px;
  background: transparent;
  padding: 10px 25px;
  cursor: pointer;
  min-width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.styles_activeButton__3Sb9d {
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border: none;
}
.styles_icon__z3q0D {
  font-size: 22px;
  color: #fafafa;
}
.styles_share__1cB84 {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin-right: 2px;
}
.styles_infoContainer__26Fuv {
  grid-column: 1/-1;
  grid-gap: 10px;
  gap: 10px;
  background: #0c1232;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.styles_info__24UpY {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.styles_infoText__3-27u {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: #fafafa;
}
.styles_url__zt4sv {
  color: #41c5db;
}
.styles_postCategoryContainer__s5wi8 {
  padding: 30px 0;
  display: flex;
  grid-gap: 25px;
  gap: 25px;
  justify-content: space-between;
  align-items: center;
}
.styles_postCategory__2-oU6 {
  background: rgba(250, 250, 250, 0.05);
  /* border: 1px solid rgba(250, 250, 250, 0.5); */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 5px;
  gap: 5px;
  /* width: 100%; */
  /* max-width: 350px; */
  padding: 1px;
}
.styles_post__2ITE4 {
  border-radius: 10px;
  padding: 8px 15px;
  width: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease;
}
.styles_activePost__1cRrs {
  background: rgba(255, 255, 255, 0.3);
  transition: 0.3s ease;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  /* .profileHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    gap: 20px;
  } */
  .styles_profileHeader__1N3Tw::before {
    display: none;
  }

  .styles_user__3WgEG {
    position: inherit;

    -webkit-transform: translate(0px);

            transform: translate(0px);
  }
  .styles_actionContainer__WE5sA {
    max-width: 400px;
  }
  .styles_actionContainer__WE5sA::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background: #343ab9;
    opacity: 0.8;
    -webkit-filter: blur(300.176px);
            filter: blur(300.176px);
    z-index: -1;
  }
}

.Leaderboard_title__2Rx-T {
  padding: 65px 0 35px;
}

.Leaderboard_table__2hy_G {
  background: rgba(250, 250, 250, 0.05);
  border: 1px solid;
  border-radius: 10px;
  padding: 0 20px;
}

.Leaderboard_tableHeader__32G_b {
  color: rgba(250, 250, 250, 0.72);
  display: flex;
  padding: 20px 30px 20px 0;
  font-weight: 200;
}

.Leaderboard_table__2hy_G,
.Leaderboard_tableHeader__32G_b,
.Leaderboard_tableItem__5DCV1 {
  border-bottom: solid 1px;
  border-color: rgba(250, 250, 250, 0.1);
}

.Leaderboard_rank__WlE4c {
  width: 12%;
  text-align: center;
}

.Leaderboard_user__1U8MQ {
  width: 40%;
  padding-left: 30px;
}

.Leaderboard_points__1xrTO {
  width: 48%;
  text-align: right;
}

.Leaderboard_tableItem__5DCV1 {
  display: flex;
  align-items: center;
  padding: 12px 30px 12px 0;
  transition: background 0.2s ease;
}

.Leaderboard_tableItem__5DCV1:last-child {
  border-bottom: none;
}

.Leaderboard_tableItem__5DCV1:hover {
  background: rgba(250, 250, 250, 0.01);
}

.Leaderboard_tableItem__5DCV1 .Leaderboard_rank__WlE4c {
  color: rgba(250, 250, 250, 0.72);
  text-align: center;
}

.Leaderboard_tableItem__5DCV1 .Leaderboard_user__1U8MQ {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Leaderboard_tableItem__5DCV1 .Leaderboard_userpic__7gNsh {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.Leaderboard_tableItem__5DCV1 .Leaderboard_points__1xrTO {
  font-weight: 700;
}

