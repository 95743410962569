.modal {
  position: fixed;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 772px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: rgba(12, 18, 50, 0.6);
  z-index: 9;
  cursor: pointer;
}

.modalWrapper {
  display: grid;
  grid-template-columns: 228px 1fr;
  align-items: center;
  gap: 25px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(150px);
  border-radius: 10px;
  padding: 50px 30px;
  padding-right: 50px;
  position: relative;
  width: 100%;
}
.close {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  color: var(--primary-color);
  cursor: pointer;
}
.image {
  width: 100%;
  border-radius: 10px;
}
.nameId {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;

  letter-spacing: -0.02em;

  color: #ffffff;
}
.name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 120%;
  color: #42ccd7;
}
.keyAndValueContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 0;
}
.keyAndValue {
  display: grid;
  grid-template-columns: 130px 1fr;
  align-items: center;
}
.key {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: rgba(250, 250, 250, 0.72);
}
.value {
  color: #42ccd7;
}
.ethValue {
  font-weight: 700;
  color: #fff;
}
.button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #fafafa;
  outline: none;

  border-radius: 10px;
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border: none;
  padding: 9px 25px;
  cursor: pointer;
  width: 100%;
}
.buttonLoader {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 991px) {
  .modal {
    width: 90%;
  }
}
@media only screen and (max-width: 767px) {
  .modal {
    top: 50px;
    width: auto;
  }
  .modalWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    padding-bottom: 20px;
  }
  .close {
    top: 12px;
  }
  .image {
    width: 280px;
    height: 150px;
    object-fit: cover;
    object-position: center;
  }
}
