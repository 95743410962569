.profileSettings {
  background: #0c1232;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 357px;
  width: 100%;
  position: absolute;
  top: 7px;
  right: 15px;
  z-index: 5;
}

.header {
  padding: 18px 15px;
  display: flex;
  align-items: center;
  gap: 25px;
}
.userImage {
  width: 80px;
  height: 80px;
}
.userName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.balanceAndAddress {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
  padding: 22px 15px;
}
.balanceContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.balanceText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: rgba(250, 250, 250, 0.7);
}

.balance {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;

  letter-spacing: -0.02em;
  color: #fafafa;
}

.xpBalance {
  font-size: 18px;
  text-shadow: 0px 0px 5px #4369cb;
}

.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;

  letter-spacing: -0.02em;

  color: #fafafa;
}
.address {
  background: rgba(250, 250, 250, 0.15);
  border-radius: 10px;
  padding: 5px;
}

.itemContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 22px 15px;
}

.iconAndItem {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.icon {
  font-size: 20px;
  color: #fafafa;
}
