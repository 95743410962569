.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 88;
}
.modalWrapp {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 700px;
  max-height: 490px;
  overflow-y: auto;
  z-index: 90;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px 30px;
  border-radius: 8px;
  height: 90%;
  background-color: var(--primary-bg);
}
.modalHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.modalHeader h3 {
  color: white;
  font-size: 22px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}
.modalHeader svg {
  min-width: 30px;
  max-width: 30px;
  object-fit: contain;
  cursor: pointer;
}
.photosDiv {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  grid-auto-rows: 1fr;
  width: 100%;
  gap: 5px;
  margin-top: 10px;
  max-height: 320px;
  height: 100%;
  padding-right: 10px;
  overflow-y: auto;
}
.photosDiv button {
  width: 100%;
  aspect-ratio: 1/1;
  background-color: transparent;
  cursor: pointer;
}
.photosDiv button img {
  border-radius: 8px;
  border: 4px solid transparent;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
  height: 100%;
}
.button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 10px 45px;
  cursor: pointer;
}
