.wrapper {
  background: #0c1232;
  border-radius: 8px;
  width: 418px;
  padding: 10px 20px;
  padding-bottom: 20px;
}
.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  padding-top: 20px;
}
.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #fafafa;
}
.close {
  color: #fafafa;
  font-size: 22px;
  cursor: pointer;
}
.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: rgba(250, 250, 250, 0.72);
  padding: 15px 0;
}
.messageBoxContainer {
  padding: 18px 0;
  display: block;
}
.messageBox {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: rgba(250, 250, 250, 0.72);
  max-width: 390px;
  width: 100%;
  margin: 0 auto;
  height: 75px;

  border: 1px solid #fafafa;
  border-radius: 10px;
  outline: none;
  background: none;
  padding: 15px;
  resize: none;
}
.button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #fafafa;
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  width: 100%;
  border: none;
  outline: none;
  padding: 8px 15px;
  margin-top: 25px;
  cursor: pointer;
}

@media only screen and (max-width: 520px) {
  .wrapper {
    width: 90%;
    padding: 25px 18px;
    padding-bottom: 18px;
  }
}
