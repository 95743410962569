.wrapper {
  background: #0c1232;
  border-radius: 8px;
  width: 418px;
  padding: 30px;
  padding-bottom: 20px;
}
.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}
.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #fafafa;
}
.close {
  color: #fafafa;
  font-size: 22px;
  cursor: pointer;
}

.arrow {
  font-size: 18px;
  color: #fafafa;
  cursor: pointer;
}
.socials {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 0.5px solid rgba(250, 250, 250, 0.72);
}
.socials:last-child {
  border: none;
}
.nameAndIcon {
  display: flex;
  align-items: center;
  gap: 10px;
}
.name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;

  color: #fafafa;
}
.socialIcon {
  width: 20px;
  height: 19.5px;
}

@media only screen and (max-width: 520px) {
  .wrapper {
    width: 90%;
    padding: 25px 18px;
    padding-bottom: 18px;
  }
}
