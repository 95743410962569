.uploadingWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
}
.uploading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;
}
.imageAndName,
.cancelContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.cancelContainer {
  cursor: pointer;
}
.image {
  width: 31px;
  height: 31px;
  border-radius: 2.1385px;
}
.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #fafafa;
}

.warning,
.close {
  color: #ff5757;
}
.close {
  cursor: pointer;
}
