.heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #ffffff;
  padding-top: 65px;
  padding-bottom: 35px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  padding: 0 10px;
  padding-top: 100px;
}
.title {
  color: #fafafa;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
.text {
  color: #fafafa;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 991px) {
  .heading {
    padding-top: 30px;
  }
  .wrapper {
    padding-top: 40px;
  }
}
