.setUpProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  min-height: calc(100vh - 101px);
}
.heading {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 115%;
  text-align: center;
  color: #ffff;
  padding-bottom: 10px;
  padding: 0 10px;
}

.profilePhotoDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.profilePhotoDiv button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #41c6da;
  padding-bottom: 8px;
  cursor: pointer;
}
.profilePhoto {
  width: 100px;
  height: 105px;
}
.userNameContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 322px;
  width: 95%;
  margin: 0 auto;
}
.inputContainer {
  border: 1px solid rgba(250, 250, 250, 0.72);
  border-radius: 10px;
  padding: 10px 12px;
  background: transparent;
  width: 100%;
  position: relative;
  transition: 0.2s ease;
}
.warningBorder {
  border: 1.5px solid #fe595a;
}
.paddingTop {
  padding-top: 24px;
  transition: 0.2s ease;
}
.label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #ffff;
  opacity: 0.6;
  transform: translateY(100%);
  transition: 0.2s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: text;
}
.showLabel {
  transform: translateY(0);
  top: 5px;

  transition: 0.2s ease;
}
.warning {
  color: #fe595a;
  opacity: 1;
  text-align: left;
  display: block;
  width: 100%;
}
.warningText {
  padding-left: 12px;
  padding-top: 2px;
}
.input {
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffff;
}
.button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 10px 45px;
  width: 100%;
  cursor: pointer;
  margin-top: 15px;
}
.disableStyle {
  cursor: not-allowed;
  opacity: 0.6;
}
.buttonLoader {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 991px) {
  .setUpProfile {
    min-height: calc(100vh - 165px);
  }
}
@media only screen and (max-width: 520px) {
  .heading {
    font-size: 32px;
  }
}
