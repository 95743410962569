.descriptionContainer {
  position: fixed;
  right: 0;
  top: 101px;
  max-width: 358px;
  width: 100%;
  max-height: calc(100vh - 101px);
  height: calc(100vh - 101px);
  overflow: auto;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(15.5px);
  transform: translate(150%);
  transition: 0.3s;
}
.descriptionWrapper {
  transform: translate(0%);
  transition: 0.3s;
}
.close {
  color: #fafafa;

  position: absolute;
  top: 10px;
  left: 18px;
  font-size: 30px;
  cursor: pointer;
  display: none;
}
.nameId {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 15px 18px;
  padding-top: 20px;
}
.name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.nameHighlight {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 120%;

  color: #42ccd7;
}
@media only screen and (max-width: 1399px) {
  .descriptionContainer {
    padding-top: 30px;
    background: #181e3c;
  }

  .close {
    display: block;
  }
}
@media only screen and (max-width: 991px) {
  .descriptionContainer {
    top: 165px;
  }
}
@media only screen and (max-width: 520px) {
  .close {
    left: 15px;
  }
  .nameId {
    padding: 15px 15px;
  }
}
@media only screen and (max-width: 400px) {
  .close {
    left: 12px;
  }
  .nameId {
    padding: 15px 12px;
  }
}
