.title {
  padding: 65px 0 35px;
}

.table {
  background: rgba(250, 250, 250, 0.05);
  border: 1px solid;
  border-radius: 10px;
  padding: 0 20px;
}

.tableHeader {
  color: rgba(250, 250, 250, 0.72);
  display: flex;
  padding: 20px 30px 20px 0;
  font-weight: 200;
}

.table,
.tableHeader,
.tableItem {
  border-bottom: solid 1px;
  border-color: rgba(250, 250, 250, 0.1);
}

.rank {
  width: 12%;
  text-align: center;
}

.user {
  width: 40%;
  padding-left: 30px;
}

.points {
  width: 48%;
  text-align: right;
}

.tableItem {
  display: flex;
  align-items: center;
  padding: 12px 30px 12px 0;
  transition: background 0.2s ease;
}

.tableItem:last-child {
  border-bottom: none;
}

.tableItem:hover {
  background: rgba(250, 250, 250, 0.01);
}

.tableItem .rank {
  color: rgba(250, 250, 250, 0.72);
  text-align: center;
}

.tableItem .user {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tableItem .userpic {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.tableItem .points {
  font-weight: 700;
}
