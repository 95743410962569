.posts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(228px, 1fr));
  gap: 5px;
}

.image {
  width: 100%;
}
.post {
  background: rgba(250, 250, 250, 0.05);
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 16px;
  overflow: hidden;
}
.detailsContainer {
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}
.token_link {
  width: 100%;
}
.id {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  padding-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.valueContainer {
  background: #224dbd;
  border-radius: 10px;
  padding: 6px 8px;
  display: flex;
  align-content: center;
  gap: 8px;
}
.value {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #fafafa;
}
.name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: rgba(250, 250, 250, 0.72);
}
.button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #fafafa;
  outline: none;
  border: 1px solid #fafafa;
  border-radius: 10px;
  background: transparent;
  padding: 10px 25px;
  cursor: pointer;
  min-width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 25px;
}
