.notificationContainer {
  background: #0c1232;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 357px;
  width: 100%;

  position: absolute;
  top: 7px;
  right: 15px;
  z-index: 5;
}
.heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;

  letter-spacing: -0.02em;

  color: #ffffff;
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
  padding: 18px 15px;
}
.notifications {
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  height: 80vh;
  overflow-y: auto;
}

.userWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.userAndnotification {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 5px;
}
.userImage {
  width: 40px;
  height: 40px;
  display: block;
  cursor: pointer;
}
.userInfo {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;

  color: #fafafa;
}
.userName {
  font-weight: 600;
  cursor: pointer;
}
.time {
  opacity: 0.72;
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 3px;
}
.button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  color: #303030;
  background: #fafafa;
  border-radius: 10px;
  padding: 8px 8px;
  cursor: pointer;
  min-width: 60px;
}
.remove {
  background: #ff5757;
}

.image {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
@media only screen and (max-width: 400px) {
  .notificationContainer {
    right: 0;
  }
  .notifications {
    padding: 15px 8px;
  }
  .userInfo {
    font-size: 13px;
  }
  .button {
    padding: 6px 4px;
    font-size: 12px;
  }
}
