.profileWrapper {
  padding-top: 50px;
  padding-left: 50px;
  position: relative;
  z-index: 2;
  margin-top: 101px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 45px;
}

.profileWrapper::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 100%;
  right: 0;
  top: 0;

  background: #343ab9;
  opacity: 0.3;
  filter: blur(300.176px);
  z-index: -1;
}

.tabsContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.profile {
  background: rgba(250, 250, 250, 0.1);
  border-radius: 10px;
  padding: 30px;
  width: 90%;
  margin: 0 auto;
}

.bannerContainer {
  padding: 20px 0;
}

.bannerImageContainer {
  width: 100%;
  position: relative;
}

.banner {
  width: 100%;
  min-height: 88px;
  max-height: 500px;
  object-fit: contain;
}

.imageInput {
  display: none;
}

.editWrapper {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.editIcon {
  font-size: 28px;
  color: var(--primary-color);
}

.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #fafafa;
  padding-bottom: 8px;
}

.user {
  width: 100px;
  height: 90px;
  cursor: pointer;
}

.warningBorder {
  border: 1.5px solid #fe595a;
}

.duplicateUsername {
  font-size: 14px;
  color: #fe595a;
}

.defaultAddressContainer {
  display: flex;
  flex-direction: column;
  padding: 7px 0;
  width: 100%;
}

.label {
  display: block;
  padding-bottom: 5px;
  cursor: pointer;
  text-transform: capitalize;
}

.inputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 25px;
  border: 1.5px solid rgba(250, 250, 250, 0.72);

  border-radius: 16px;

  position: relative;
  cursor: pointer;
}

.inputContainer:hover {
  border-radius: 16px 16px 0 0;
  border-bottom: none;
}

.addressContainer {
  position: absolute;
  left: -1px;
  top: 53px;
  z-index: 2;
  width: calc(100% + 2px);

  border: 1.5px solid rgba(250, 250, 250, 0.72);
  border-top: none;
  border-radius: 0 0 16px 16px;

  padding: 15px;
  background: var(--primary-bg);

  display: none;
}

.address {
  cursor: pointer;
  padding: 5px 0;
}

.inputContainer:hover .addressContainer {
  display: flex;
  flex-direction: column;
}

.addressContainer:hover {
  display: flex;
  flex-direction: column;
}

.inputContainer:hover .arrow {
  transform: rotate(-180deg);
}

.input {
  background-color: transparent;
  display: block;
  width: 100%;
  margin: 0 auto;

  padding: 15px 20px;
  outline: none;
  border: none;
}

.textAreaContainer {
  padding: 5px 0;
}

.textArea {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #fafafa;
  background-color: transparent;
  width: 100%;
  border: 1.5px solid rgba(250, 250, 250, 0.72);
  border-radius: 16px;
  padding: 15px 20px;
}

.arrow {
  color: var(--primary-color);
  font-size: 18px;
}

.button {
  width: 100%;
  padding: 15px 25px;
  margin-top: 20px;
}

@media only screen and (max-width: 991px) {
  .profileWrapper {
    margin-top: 165px;
  }
}
@media only screen and (max-width: 767px) {
  .profileWrapper {
    grid-template-columns: 1fr;
    padding-left: 0;
  }
  .tabsContainer {
    padding: 0 40px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 25px;
    align-items: center;
  }
}
@media only screen and (max-width: 520px) {
  .profile {
    padding: 30px 12px;
    width: 100%;
  }
  .tabsContainer {
    justify-content: center;
  }
  .text {
    font-size: 14px;
  }
}
@media only screen and (max-width: 450px) {
  .inputContainer {
    padding-right: 10px;
  }
  .input {
    font-size: 14px;
    padding: 15px 10px;
  }
  .textArea {
    padding: 15px 10px;
  }
  .addressInput {
    font-size: 10px;
  }
  .address {
    font-size: 10px;
  }
  .addressContainer {
    top: 43px;
  }
}
