.createNewPostContainer {
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-top: 40px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #fafafa;
}

.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
}

.chooseWallet {
  display: grid;
  grid-template-columns: 1fr 200px;
  gap: 15px;
  align-items: center;
}

.button {
  border-radius: 10px;
}

.buttonActive {
  cursor: pointer;
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .chooseWallet {
    grid-template-columns: 1fr;
  }
}
