.container {
  display: flex;
  align-items: center;

  color: #ffffff;
}

.container input[type="checkbox"] {
  display: none;
}

.checkmark {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #42ccd7;
  margin-right: 5px;
  position: relative;

  cursor: pointer;
}

.container input[type="checkbox"]:checked + .checkmark:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;

  background: #42ccd7;
  border-radius: 50%;
}
.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}
