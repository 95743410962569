@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: 100%;
}
.loadingSpinner {
  width: 55px;
  height: 55px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #0c1232; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
@media only screen and (max-width: 520px) {
  .loadingSpinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #0c1232; /* Black */
  }
}
