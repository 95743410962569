.inputContainer {
  display: flex;
  flex-direction: column;
  padding: 7px 0;
  width: 100%;
}
.label {
  display: block;
  padding-bottom: 5px;
  cursor: pointer;
  text-transform: capitalize;
}
.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;

  color: #fafafa;
}
.input {
  background-color: transparent;
  display: block;
  width: 100%;
  margin: 0 auto;
  border: 1.5px solid rgba(250, 250, 250, 0.72);
  border-radius: 16px;
  padding: 15px 20px;
  outline: none;
}
@media only screen and (max-width: 520px) {
  .input {
    font-size: 14px;
    padding: 15px 10px;
  }
}
