.allNft {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110.18px, 1fr));
  gap: 8px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 24px;
  margin-top: 15px;
}
.imageContainer {
  cursor: pointer;
  border-radius: 12px;
}
.active {
  border: 3px solid #224dbd;
}
.image {
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
  display: block;
}

@media only screen and (max-width: 991px) {
  .descriptionContainer {
    top: 165px;
    max-height: calc(100vh - 165px);
    height: calc(100vh - 165px);
  }
}
@media only screen and (max-width: 575px) {
  .allNft {
    padding: 15px;
    gap: 4px;
  }
}
