.heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 125%px;
  text-align: left;

  /* Continent Full Moon */

  color: #fafafa;
}

.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: rgba(250, 250, 250, 0.72);
}

.button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 7px 45px;
  margin-top: 30px;
  cursor: pointer;
}
