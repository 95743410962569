.editInformationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 50;
  position: relative;
}
.editInformation {
  border-radius: 0px 0px 20px 0px;
  background: #181e3c;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.7);

  max-width: 1143px;

  margin: 0 auto;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #242c56;
  border-radius: 20px 20px 0px 0px;
  padding: 15px 14px;
  position: relative;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 125%;
  color: #fafafa;
}
.wrapper {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;

  min-height: 554px;
  max-height: 90vh;
  overflow-y: auto;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 0px 0px 0px 20px;
}
.details {
  display: flex;
  flex-direction: column;
}
.userContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 18px;
}
.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;

  color: #fafafa;
}
.editText {
  font-weight: 400;
}
.profile {
  width: 32px;
  height: 32px;
}
.edits,
.tagPeople,
.listForSaleContainer {
  display: flex;
  padding: 18px;
  gap: 6px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
  min-height: 130px;
}
.edit {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.tagPeople {
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
  min-height: auto;
}
.userIcon {
  color: #fff;
  font-size: 22px;
}
.listForSaleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.listForSale {
  display: flex;
  gap: 6px;
}
.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-top: 6px;
  padding-left: 25px;
}
.btnDiv {
  padding: 18px;
  display: grid;
  grid-template-columns: 147px 1fr;
  align-items: center;
  gap: 20px 20px;
  margin-top: auto;
}
.invertBtn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid white;
  padding: 10px 45px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.invertBtn:hover {
  background-color: white;
  color: black;
}
.button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: var(--primary-color);
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border-radius: 10px;
  padding: 10px 45px;
  cursor: pointer;
}
.close {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 91;
  color: #fff;
  font-size: 24px;
}
.buttonLoader {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    grid-template-columns: 1fr;
    gap: 25px;
  }
  .editInformation {
    border-radius: 0 0 20px 20px;
  }
  .image {
    border-radius: 0;
  }
}
@media only screen and (max-width: 520px) {
  .btnDiv {
    padding: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px 10px;
  }
  .image {
    border-radius: 0px;
  }
  .userContainer,
  .edits,
  .tagPeople,
  .buttonDiv.listForSale {
    padding: 18px 10px;
  }
}
