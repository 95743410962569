.pageContainer {
  padding-bottom: 30px;
}
.profileHeader {
  display: grid;
  grid-template-columns: 1fr 177px;
  align-items: start;
  gap: 50px;
  padding-top: 65px;
  z-index: 1;
  position: relative;
}
.profileHeader::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 40%;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #343ab9;
  opacity: 0.8;
  filter: blur(300.176px);
  z-index: -1;
}
.userInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}
.user {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  width: 120px;
  height: 108px;
}
.name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #fafafa;
  padding-top: 10px;
}
.addressContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  background: rgba(250, 250, 250, 0.15);
  border-radius: 10px;
  padding: 4px 8px;
}
.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: rgba(250, 250, 250, 0.7);
}
.downArrow {
  color: var(--primary-color);
  cursor: pointer;
}
.address {
  color: #fff;
}
.actionContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #fafafa;
  outline: none;
  border: 1px solid #fafafa;
  border-radius: 10px;
  background: transparent;
  padding: 10px 25px;
  cursor: pointer;
}
.activeButton {
  background: linear-gradient(89.99deg, #224dbd 0.66%, #3069ff 61.01%, #42ccd7 99.99%);
  border: none;
}
.shareContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
.infoContainer {
  grid-column: 1/-1;
  gap: 10px;
  background: #0c1232;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info {
  display: flex;
  align-items: center;
  gap: 8px;
}
.infoText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: #fafafa;
}
.url {
  color: #41c5db;
}
.postTypesContainer {
  padding: 30px 0;
  display: flex;
  gap: 25px;
  justify-content: space-between;
  align-items: center;
}
.postTypes {
  background: rgba(250, 250, 250, 0.05);
  border: 1px solid rgba(250, 250, 250, 0.5);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  width: 249px;
  padding: 1px;
}
.postType {
  border-radius: 8px;
  padding: 6.5px 15px;
  width: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s ease;
}
.activePostType {
  background: rgba(255, 255, 255, 0.3);
  transition: 0.3s ease;
}
.postTypeText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(250, 250, 250, 1);
}
.activePostType .postTypeText {
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  .profileHeader {
    grid-template-columns: 1fr;
    padding: 30px 0;
  }
  .profileHeader::before {
    display: none;
  }
  .user {
    position: inherit;

    transform: translate(0px);
  }
  .actionContainer {
    max-width: 400px;
  }
  .actionContainer::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #343ab9;
    opacity: 0.8;
    filter: blur(300.176px);
    z-index: -1;
  }
}
@media only screen and (max-width: 520px) {
  .postCategoryContainer {
    flex-direction: column;
  }
}
