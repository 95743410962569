.hexagon {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: teal;
  -webkit-clip-path: polygon(
    98.66025% 45%,
    99.39693% 46.5798%,
    99.84808% 48.26352%,
    100% 50%,
    99.84808% 51.73648%,
    99.39693% 53.4202%,
    98.66025% 55%,
    78.66025% 89.64102%,
    77.66044% 91.06889%,
    76.42788% 92.30146%,
    75% 93.30127%,
    73.4202% 94.03794%,
    71.73648% 94.48909%,
    70% 94.64102%,
    30% 94.64102%,
    28.26352% 94.48909%,
    26.5798% 94.03794%,
    25% 93.30127%,
    23.57212% 92.30146%,
    22.33956% 91.06889%,
    21.33975% 89.64102%,
    1.33975% 55%,
    0.60307% 53.4202%,
    0.15192% 51.73648%,
    0% 50%,
    0.15192% 48.26352%,
    0.60307% 46.5798%,
    1.33975% 45%,
    21.33975% 10.35898%,
    22.33956% 8.93111%,
    23.57212% 7.69854%,
    25% 6.69873%,
    26.5798% 5.96206%,
    28.26352% 5.51091%,
    30% 5.35898%,
    70% 5.35898%,
    71.73648% 5.51091%,
    73.4202% 5.96206%,
    75% 6.69873%,
    76.42788% 7.69854%,
    77.66044% 8.93111%,
    78.66025% 10.35898%
  );
  clip-path: polygon(
    98.66025% 45%,
    99.39693% 46.5798%,
    99.84808% 48.26352%,
    100% 50%,
    99.84808% 51.73648%,
    99.39693% 53.4202%,
    98.66025% 55%,
    78.66025% 89.64102%,
    77.66044% 91.06889%,
    76.42788% 92.30146%,
    75% 93.30127%,
    73.4202% 94.03794%,
    71.73648% 94.48909%,
    70% 94.64102%,
    30% 94.64102%,
    28.26352% 94.48909%,
    26.5798% 94.03794%,
    25% 93.30127%,
    23.57212% 92.30146%,
    22.33956% 91.06889%,
    21.33975% 89.64102%,
    1.33975% 55%,
    0.60307% 53.4202%,
    0.15192% 51.73648%,
    0% 50%,
    0.15192% 48.26352%,
    0.60307% 46.5798%,
    1.33975% 45%,
    21.33975% 10.35898%,
    22.33956% 8.93111%,
    23.57212% 7.69854%,
    25% 6.69873%,
    26.5798% 5.96206%,
    28.26352% 5.51091%,
    30% 5.35898%,
    70% 5.35898%,
    71.73648% 5.51091%,
    73.4202% 5.96206%,
    75% 6.69873%,
    76.42788% 7.69854%,
    77.66044% 8.93111%,
    78.66025% 10.35898%
  );
}
.hexagon:first-child {
  -webkit-clip-path: polygon(
    92.32051% 40%,
    93.79385% 43.1596%,
    94.69616% 46.52704%,
    95% 50%,
    94.69616% 53.47296%,
    93.79385% 56.8404%,
    92.32051% 60%,
    79.82051% 81.65064%,
    77.82089% 84.50639%,
    75.35575% 86.97152%,
    72.5% 88.97114%,
    69.3404% 90.44449%,
    65.97296% 91.34679%,
    62.5% 91.65064%,
    37.5% 91.65064%,
    34.02704% 91.34679%,
    30.6596% 90.44449%,
    27.5% 88.97114%,
    24.64425% 86.97152%,
    22.17911% 84.50639%,
    20.17949% 81.65064%,
    7.67949% 60%,
    6.20615% 56.8404%,
    5.30384% 53.47296%,
    5% 50%,
    5.30384% 46.52704%,
    6.20615% 43.1596%,
    7.67949% 40%,
    20.17949% 18.34936%,
    22.17911% 15.49361%,
    24.64425% 13.02848%,
    27.5% 11.02886%,
    30.6596% 9.55551%,
    34.02704% 8.65321%,
    37.5% 8.34936%,
    62.5% 8.34936%,
    65.97296% 8.65321%,
    69.3404% 9.55551%,
    72.5% 11.02886%,
    75.35575% 13.02848%,
    77.82089% 15.49361%,
    79.82051% 18.34936%
  );
  clip-path: polygon(
    92.32051% 40%,
    93.79385% 43.1596%,
    94.69616% 46.52704%,
    95% 50%,
    94.69616% 53.47296%,
    93.79385% 56.8404%,
    92.32051% 60%,
    79.82051% 81.65064%,
    77.82089% 84.50639%,
    75.35575% 86.97152%,
    72.5% 88.97114%,
    69.3404% 90.44449%,
    65.97296% 91.34679%,
    62.5% 91.65064%,
    37.5% 91.65064%,
    34.02704% 91.34679%,
    30.6596% 90.44449%,
    27.5% 88.97114%,
    24.64425% 86.97152%,
    22.17911% 84.50639%,
    20.17949% 81.65064%,
    7.67949% 60%,
    6.20615% 56.8404%,
    5.30384% 53.47296%,
    5% 50%,
    5.30384% 46.52704%,
    6.20615% 43.1596%,
    7.67949% 40%,
    20.17949% 18.34936%,
    22.17911% 15.49361%,
    24.64425% 13.02848%,
    27.5% 11.02886%,
    30.6596% 9.55551%,
    34.02704% 8.65321%,
    37.5% 8.34936%,
    62.5% 8.34936%,
    65.97296% 8.65321%,
    69.3404% 9.55551%,
    72.5% 11.02886%,
    75.35575% 13.02848%,
    77.82089% 15.49361%,
    79.82051% 18.34936%
  );
  object-fit: cover;
  object-position: center;
  background-color: #616478;
}
