.sidebar {
  position: fixed;
  width: 265px;
  padding: 0 35px;
  padding-top: 50px;
  top: 101px;
  min-height: calc(100vh - 101px);

  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.sidebar::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 40%;
  right: 0;
  top: 60%;
  transform: translateY(-50%);
  background: #343ab9;
  opacity: 0.8;
  filter: blur(300.176px);
  z-index: -1;
}
.navItemContainer {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 0 15px;
  border-radius: 10px;
  transition: background 0.2s;
}
.navItemContainer:not(.navActive):hover {
  background: rgba(255, 255, 255, 0.1);
}
.navActive {
  background: rgba(21, 60, 159, 0.8);
}
.navItem {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #fafafa;
  padding: 12px 0;
}

.icon {
  margin-right: 7px;
}

.button {
  font-family: Helvetica Neue;
  font-weight: 500;
  line-height: 20px;
  color: var(--primary-color);
  border-radius: 25px;
  padding: 12px 16px 14px;
  margin-top: 30px;
  cursor: pointer;
  transition: opacity 0.2s;
}
.button:hover {
  opacity: 0.9;
}

.referralContainer {
  width: 100%;
  margin: auto 0 45px;
}

.referralButton .button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 68px;
  background-image: radial-gradient(220px 190px at 89% 35%, #343ab955, transparent 150%),
    radial-gradient(220px 190px at 15% 60%, #343ab933, transparent 150%),
    linear-gradient(293.96deg, #12193b -26.79%, #141a42 36.61%, #004d49 100%);
  margin-top: 20px;
}

.referralButton .button > div:nth-child(2) {
  font-size: 12px;
  margin-top: 2px;
}

@media only screen and (max-width: 991px) {
  .sidebar {
    top: 165px;
    min-height: calc(100vh - 165px);
    transform: translateX(-150%);
    padding: 0 30px;
    padding-top: 30px;
    transition: 0.5s ease;
  }
  .showSidebar {
    transform: translateX(0%);
    transition: 0.5s ease;
  }
}
