.findFriendsContainer {
  padding: 40px 0;
  padding-bottom: 70px;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  letter-spacing: -0.02em;
  text-align: left;
  width: 100%;
  color: #ffffff;

  padding-bottom: 20px;
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(267px, 1fr));

  gap: 15px;

  width: 100%;
}

@media only screen and (max-width: 520px) {
  .title {
    font-size: 28px;
  }
}
