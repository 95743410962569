.topUser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #0c1232;
  border-radius: 10px;
  padding: 15px;
}
.userImage {
  width: 150px;
  height: 150px;
}
.userName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  color: #fafafa;
}
.addFriend {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  color: #303030;
  background: #fafafa;
  border-radius: 10px;
  padding: 12px 15px;
  cursor: pointer;
  min-width: 130px;
  transition: opacity 0.2s;
}
.addFriend:hover {
  opacity: 0.9;
}
