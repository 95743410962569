.marketPlace {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 101px);
}
.heading {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 115%;
  text-align: center;
  color: #ffff;
  padding-bottom: 10px;
}
@media only screen and (max-width: 991px) {
  .marketPlace {
    min-height: calc(100vh - 165px);
  }
}
@media only screen and (max-width: 520px) {
  .heading {
    font-size: 32px;
  }
}
